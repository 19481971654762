import React, { useEffect } from 'react';
import { useLogin, useTheme } from "react-admin";
import { Button, Card, Typography } from '@mui/material';
import { styled } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import { sentryErrorHandler } from '../api/SentryErrorHandler';

const MainContainer = styled('div')(({ theme }) => ({
  backgroundColor: "#e7e6e6",
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 300,
  padding: "2em",
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)", // Soft shadow for depth
  borderRadius: "16px",
}));

const LogoContainer = styled('div')({
  marginBottom: "1.5em",
  display: "flex",
  justifyContent: "center",
});

const StyledButton = styled(Button)(({ theme }) => ({
  margin: "2em auto",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "#ffffff",
  color: "#4285F4",
  borderRadius: "8px",
  padding: "12px 24px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  '&:hover': {
    backgroundColor: "#f5f5f5",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
  },
  '& svg': {
    marginRight: "8px", // Space between icon and text
  },
}));

let client;

const loadGsiScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const StyledReactLoginPage = ({ className }) => {
  const login = useLogin();
  const navigate = useNavigate();
  const [theme, setTheme] = useTheme();

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';
    const id = process.env.REACT_APP_GSUITE_CLIENT_ID;

    loadGsiScript(src)
      .then(() => {
        /*global google*/
        client = google.accounts.oauth2.initTokenClient({
          client_id: id,
          scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/admin.directory.user.readonly",
          callback: (tokenResponse) => {
            login({ 'access_token': tokenResponse.access_token })
              .then(() => {
                setTheme('light');
                navigate('/');
              })
              .catch((error) => sentryErrorHandler(error, 'GoogleIdentityServices', 'loginCallback'));
          },
        });
      })
      .catch((error) => { sentryErrorHandler(error, 'GoogleIdentityServices','loadGsiScript') });

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);

  return (
      <MainContainer className={className}>
        <StyledCard>
          <LogoContainer>
            <img src="/IA.svg" height="80" alt="Logo" style={{ objectFit: "contain", width: "auto", maxHeight: "80px" }} />
          </LogoContainer>
          <Typography variant="h6" align="center" gutterBottom>Eleos Internal Admin</Typography>
          <StyledButton variant="outlined" onClick={() => client.requestAccessToken()}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" />
              <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" />
              <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" />
              <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" />
              <path d="M1 1h22v22H1z" fill="none" />
            </svg>
            &nbsp; Sign in with Google
          </StyledButton>
        </StyledCard>
      </MainContainer>
  );
};

export default StyledReactLoginPage;

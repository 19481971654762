import React, { useState } from "react";
import { useDataProvider } from "react-admin";
import JSONPretty from 'react-json-pretty';
import DownloadLink from 'react-download-link';
import GetAppIcon from '@mui/icons-material/GetApp';
import { CircularProgress, TextField, Button, IconButton } from '@mui/material';
import { RouteUploadComponent } from './RouteUpload';

export const RouteImportForm = () => {
  const dataProvider = useDataProvider();
  const [apiKey, setApiKey] = useState("");
  const [url, setUrl] = useState("https://router.hereapi.com/v8/import?lang=en-us&return=routeHandle,travelSummary,polyline&spans=duration,length,segmentId,notices&transportMode=truck&units=metric");
  const [routeData, setRouteData] = useState({ trace: [] });
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});

  const updateRouteData = (data) => {
    setRouteData({ trace: data });
    setLoading(false);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!apiKey) {
      alert("Please enter a HERE API key");
      setLoading(false);
      return;
    }
    if (!url) {
      alert("Please enter a URL");
      setLoading(false);
      return;
    }
    if (routeData.trace.length === 0) {
      alert("Please upload a snapshot JSON file");
      setLoading(false);
      return;
    }

    const requestUrl = `${url}&apikey=${apiKey}`;

    try {
      const { data } = await dataProvider.update('here_call', {
        userInput: true,
        url: requestUrl,
        body: JSON.stringify(routeData),
      });
      setResponse(data);
    } catch (error) {
      alert(`Request failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const RenderResults = () => {
    if (loading) {
      return <div style={{ marginLeft: "40%" }}><CircularProgress /></div>;
    }
    if (JSON.stringify(response).length > 10) {
      return (
        <div style={{ width: "600px", marginLeft: "0" }}>
          <p>Download Response Data <DownloadField data={response} /></p>
          <JSONPretty id="json-pretty" json={response}></JSONPretty>
        </div>
      );
    }
    return null;
  };

  const DownloadField = ({ data }) => (
    <DownloadLink
      label={<IconButton color="primary"><GetAppIcon /></IconButton>}
      filename={`${data.username}-${data.loadId}.json`}
      exportFile={() => JSON.stringify(data)}
    />
  );
  const RenderRequestDataDownload = (requestData) => {
    if (JSON.stringify(requestData).length > 20) {
      return (
        <div style={{ marginLeft: "5%" }}>
          <p>Download Request Body<DownloadField data={requestData} /></p>
        </div>
      );
    }
  }

  return (
    <div>
      <h2>Import Snapshot into HERE</h2>
      <h2>Import Snapshot into HERE</h2>
      <p>This form is used to send a request to HERE which contains route data. HERE will try to import this route against their road network and return information about the import</p>
      <p>How to use...</p>
      <ol>
        <li>Input the API key, to retrieve the API key
          <ol>
            <li>Go to the <a href="https://demo.routing.ext.here.com/" target="_blank"> HERE ref client </a></li>
            <li>Click the settings/gear icon at the top right</li>
            <li>Click on App Settings</li>
            <li>Copy the API key</li>
          </ol>
        </li>
        <li>Upload snapshot JSON, which can be downloaded from the Route Snapshot Form or pulled from s3
          <ul>
            <li>This snapshot JSON will then be parsed into trace points which is what HERE consumes during the import request</li>
            <li>The trace points can be downloaded seperately once you upload the snapshot JSON, if desired</li>
          </ul>
        </li>
      </ol>
      <div style={{ border: "2px solid gray", width: "80%" }}>
        <form onSubmit={submitHandler}>
          <div style={{ margin: "8px" }}>
            <TextField
              variant="outlined"
              label="API Key"
              fullWidth
              size="small"
              onChange={(e) => setApiKey(e.target.value)}
            />
          </div>
          <div style={{ margin: "8px" }}>
            <TextField
              variant="outlined"
              label="URL"
              value={url}
              size="large"
              multiline
              fullWidth
              rows={5}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
        </form>
        <div style={{ margin: "8px" }}>
          <RouteUploadComponent updateLoading={setLoading} updateRouteData={updateRouteData} />
        </div>
        {RenderRequestDataDownload(routeData)}
      </div>

      <form onSubmit={submitHandler}>
        <div style={{ margin: "8px" }}>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Send Request"}
          </Button>
        </div>
      </form>

      {RenderResults()}
    </div>
  );
};

export default RouteImportForm;
import { sentryErrorHandler } from "../api/SentryErrorHandler";

/*************************************************************
* An error WILL prevent a build from being triggered.
* If any of the checks fail, an alert will be displayed.
**************************************************************/
export const checkIconReqs = async (width, height, asset) => {
    if (height !== width) {
        alert(`Invalid ${asset} dimensions! App Icon dimensions is not square. Uploaded image is ${width}x${height}.`);
        return false;
    } else if (width > 1800) {
        alert(`Invalid ${asset} dimensions! App Icon dimensions are too large. Uploaded image is ${width}x${height}. Resize image.`);
        return false;
    }
    return true;
};

/*************************************************************
* An error WILL prevent a build from being triggered.
* If any of the checks fail, an alert will be displayed.
**************************************************************/
export const checkLogoReqs = async (width, height, asset) => {
    if (height >= width) {
        alert(`Invalid ${asset} dimensions! Logo dimensions is not rectangular. Uploaded image is ${width}x${height}`);
        return false;
    } else if (width > 1800) {
        alert(`Invalid ${asset} dimensions! Logo dimensions are too large. Uploaded image is ${width}x${height}. Resize image.`);
        return false;
    } else {
        return true;
    }
};

export const checkImageRequirements = async (url, checkFunction, asset) => {
    if (url !== "null") {
        try {
            // Don't proceed until the image has been loaded or an error has occurred.
            const img = await new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = () => reject(`Error loading image from URL: ${url}`);
                img.src = url;
            });
            if (!(await checkFunction(img.width, img.height, asset))) {
                return false;
            }
            return true;
        } catch (error) {
            sentryErrorHandler(error, 'checkImageRequirements')
            return false;
        }
    } else {
        alert(`Warning! Missing ${asset}!`);
        return true;
    }
};
import React, { useState } from "react";
import PublishIcon from '@mui/icons-material/Publish';
import { useDataProvider, useNotify, useRecordContext } from "react-admin";
import { sentryErrorHandler } from "../api/SentryErrorHandler";

export const FileUploadComponent = ({asset}) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const [fileReader] = useState(new FileReader());
  const clientKey = record.key;
  const notify = useNotify();
  
  const convertToFormEncoded = (data) => {
    return Object.entries(data)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");
  };

  const handleFileUpload = (event) => {
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(event.target.files[0]);
  }

  const handleFileRead = async () => {
    let jsonData;
    let formEncodedCode;
    try{ jsonData = JSON.parse(fileReader.result) } 
    catch(e){ alert("Invalid JSON"); return;}
    
    if (!("code" in jsonData)) {
      alert("Invalid JSON: 'code' not present");
      return;
    }
    if (asset !== "actions" && jsonData.definition !== undefined && typeof jsonData.definition !== "string") {
      alert("Invalid JSON: 'definition' must be of type string");
      return;
    }
    if (asset === "screens") {
      if (jsonData.definition === null) delete jsonData.definition;
      if (jsonData.definition_javascript === null) delete jsonData.definition_javascript;
    } 
    else if (["dashboards", "menus"].includes(asset)) {
      jsonData.items = JSON.stringify(jsonData.items);
      formEncodedCode = jsonData.code;
      jsonData = convertToFormEncoded(jsonData);
    }
    const assetCode = formEncodedCode || jsonData.code;
    await dataProvider.update(asset, {id: clientKey, code: assetCode, data: jsonData})
    .then(({ data }) => {
      notify(`${asset.slice(0, -1)} (${assetCode}) uploaded/updated successfully`, {type: 'success'});
    }).catch(error => {
      sentryErrorHandler(error, asset)
      notify(`${asset.slice(0, -1)} (${assetCode}) failed to upload. Check Sentry.`, {type: 'error'});
    })
  };

  const buttonStyle = {
     display: "inline-block",
     margin: "0 0.3em 0.3em 0",
     borderRadius: "2em",
     boxSizing: "border-box",
     textDecoration: "none",
     fontFamily: 'Roboto',
     fontWeight: "300",
     color: "#FFFFFF",
     backgroundColor: "#4eb5f1",
     textAlign: "center",
     cursor: "pointer"
  }

  const uploadBtn = <button style={buttonStyle}><label aria-label="upload-btn" htmlFor="inputfile" style={{cursor: "pointer"}}><PublishIcon /></label></button>
  return (
    <div id="FileHandler">
      <input type="file" className="inputfile" id="inputfile" onChange={(e)=> {handleFileUpload(e); e.target.value=null }} hidden/>
      <h4>Upload {uploadBtn}</h4>
    </div>
  );
}

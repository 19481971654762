import logo from '../resources/img/IA.svg';
import { CircularProgress } from '@mui/material';

export const IALoading = (props) => {
    const size = props.size || 40
    return (
        <div style={{ position: "relative", width: size, height: size }}>
            <img
                src={logo}
                alt="Internal Admin Loading Logo"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: size * 1.1,
                    height: size * 1.1,
                }}
            />
            <CircularProgress
                variant="indeterminate"
                size={size}
                thickness={2}
                sx={{
                    color: "white",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
            />
        </div>
    );
};
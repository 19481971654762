import React, { useState } from 'react';
import { Box, Collapse, IconButton, List, ListItem, Button as MaterialButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { HowToReg, KeyboardArrowDownSharp, KeyboardArrowRight, CheckCircleOutlineSharp, HighlightOff, SentimentSatisfiedAlt } from "@mui/icons-material";
import { renderIfPermissions } from "../api/permissionManager";
import { useNotify, usePermissions, useRefresh, useShowController, useUpdate, useRedirect } from 'react-admin';

const ApproveDraftButton = (props) => {
  const { record } = useShowController();

  const notificationMapping = (rsc) => {
    switch (rsc) {
      case 'customer_key_rotation_drafts':
        return "Customer's Drive Axle Key has been successfully rotated."
      case 'customer_unsubscribe_drafts':
        return "Successfully submitted customer unsubscription."
      case 'backend_user_migration_drafts':
        return "Successfully migrated backend user."
      case 'backend_user_invite_drafts':
        return "Successfully invited backend user."
      case 'backend_user_role_drafts':
        return "Successfully modified backend user's roles."
      case 'scanning_user_delete_drafts':
        return "Successfully deleted scanning user."
      default:
        return "Successfully approved draft."
    }
  }

  const getMeta = () => {
    switch (props.rsc) {
      case 'backend_user_role_drafts':
        return { "email": record.id }
      case 'scanning_user_delete_drafts':
        return { "email": record.email }
      default:
        return ""
    }
  }

  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const [update, { isLoading, error }] = useUpdate(
    `${props.rsc}`,
    {
      id: props.draft.id,
      meta: getMeta()
    },
    {
      onSuccess: (data) => {
        refresh();
        notify(notificationMapping(props.rsc));
        if (props.rsc == 'scanning_user_delete_drafts') {
          redirect("/scanning_users")
        }
      },
      onError: (error) => {
        notify(`Draft information update error: ${error.message}`, { type: 'error' });
      },
    }
  );

  const handleClick = async () => {
    await update();
    refresh();
  };

  if (error) return <></>;
  if (isLoading) return <></>;

  const current_user_email = localStorage.getItem("user_email");

  return (
    <>
      {props.draft.verification !== "approved" ? (
        <MaterialButton
          id={`approve-button-${props.draft.id}`}
          color="primary"
          variant="contained"
          onClick={handleClick}
          style={{ fontSize: "0.8125rem", width: "8rem" }}
          disabled={current_user_email === props.draft.superuser_email}
        >
          <HowToReg style={{ fontSize: "1.25rem", paddingRight: "0.5rem" }} />
          Approve
        </MaterialButton>
      ) : (
        <MaterialButton
          id={`approved-button-${props.draft.id}`}
          color="primary"
          style={{ fontSize: "0.8125rem", width: "8rem", backgroundColor: '#b0d9b0' }}
          disabled
          variant="contained"
        >
          <CheckCircleOutlineSharp style={{ fontSize: "1.25rem", paddingRight: "0.5rem" }} />
          Approved
        </MaterialButton>
      )
      }
    </>
  );
};

const EmptyDrafts = () => {
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h5" paragraph>There are no drafts at this time.</Typography>
      <Typography><SentimentSatisfiedAlt /></Typography>
    </Box>
  )
};

const DraftsTable = ({ data, resource }) => {
  const { permissions } = usePermissions();
  const [open, setOpen] = useState({});
  const handleExpand = (id) => {
    setOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Calculate the difference between the current time and occurred_at
  const calculateTimeDiff = (time, cancellation_date) => {
    if (!time) return false;
    if (cancellation_date < new Date().toISOString()) return false;
    const occurredAt = new Date(time);
    const currentTime = new Date();
    const hoursDifference = occurredAt ? Math.abs(currentTime - occurredAt) / 36e5 : null;
    const isWithin24Hours = hoursDifference !== null && hoursDifference < 24;
    return isWithin24Hours;
  };

  const getPermResource = () => {
    switch (resource) {
      case 'backend_user_migration_drafts':
      case 'backend_user_role_drafts':
        return 'backend_users'
      default:
        return 'customers'
    }
  }

  return (
    <div style={{ marginRight: '1em' }}>
      {data && data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{ fontWeight: 'bolder' }}>ID</TableCell>
                {data[0].email ? (<TableCell style={{ fontWeight: 'bolder' }}>Requested Email</TableCell>) : ''}
                <TableCell style={{ fontWeight: 'bolder' }}>Created At</TableCell>
                <TableCell style={{ fontWeight: 'bolder' }}>Created By</TableCell>
                <TableCell style={{ fontWeight: 'bolder' }}>Cancellation Date</TableCell>
                <TableCell style={{ fontWeight: 'bolder' }}>Status</TableCell>
                <TableCell style={{ fontWeight: 'bolder' }}>Approved By</TableCell>
                <TableCell style={{ fontWeight: 'bolder' }}>Approved At</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <>
                  <TableRow>
                    <TableCell>
                      <IconButton size="small" onClick={() => handleExpand(item.id)}>
                        {open[item.id] ? <KeyboardArrowDownSharp /> : <KeyboardArrowRight />}
                      </IconButton>
                    </TableCell>
                    <TableCell>{item.id}</TableCell>
                    {item.email ? (<TableCell>{item.email}</TableCell>) : ''}
                    <TableCell>{item.occurred_at}</TableCell>
                    <TableCell>{item.superuser_email}</TableCell>
                    <TableCell>{item.event.params.cancellation_date}</TableCell>
                    <TableCell>{item.verification}</TableCell>
                    {item.secondary_superuser_email !== "null" ? (<TableCell>{item.secondary_superuser_email}</TableCell>) : <TableCell />}
                    {item.verified_at !== "null" ? (<TableCell>{item.verified_at}</TableCell>) : (<TableCell />)}
                    {calculateTimeDiff(item.occurred_at, item.event.params.cancellation_date) ?
                      (renderIfPermissions(permissions, (getPermResource()), "write", <TableCell align="center"><ApproveDraftButton draft={item} rsc={resource} /></TableCell>))
                      :
                      (<TableCell align="center">
                        <MaterialButton
                          id={`expired-button-${item.id}`}
                          style={{ fontSize: "0.8125rem", width: "8rem" }}
                          disabled
                          variant="contained"
                        >
                          <HighlightOff
                            style={{ fontSize: "1.25rem", paddingRight: "0.5rem" }}
                          />
                          Expired
                        </MaterialButton>
                      </TableCell>)}
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={item.email ? 9 : 8}>
                      <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <List>
                            <ListItem>
                              <Typography variant="body6">
                                <b>Path:</b> <span style={{ marginLeft: '2.8rem' }}>{item.event.path}</span>
                              </Typography>
                            </ListItem>
                            {item.event.params.preserve_backend_user_email && (
                              <ListItem>
                                <Typography variant="body6">
                                  <b>Preserve Backend User Email:</b>
                                  <span style={{ marginLeft: '2.8rem' }}>{item.event.params.preserve_backend_user_email}</span>
                                </Typography>
                              </ListItem>
                            )}
                            {item.event.params._json && (
                              <ListItem>
                                <Typography variant="body6">
                                  <b><u>{item.event.params.email}</u> should only have the following roles:</b>
                                  <Table size='small'>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell><b>Code</b></TableCell>
                                        <TableCell><b>Name</b></TableCell>
                                        <TableCell><b>Description</b></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    {item.event.params._json.map((role, index) => (
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>{role.code}</TableCell>
                                          <TableCell>{role.name}</TableCell>
                                          <TableCell>{role.description}</TableCell>
                                        </TableRow>
                                      </TableBody>
                                    ))}
                                  </Table>
                                </Typography>
                              </ListItem>
                            )}
                          </List>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyDrafts />
      )}
    </div>
  );
};

export default DraftsTable;

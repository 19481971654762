import { useTheme } from "@emotion/react";
import { createSvgIcon, SvgIcon } from "@mui/material";

export const SendGridIcon = (props) => {
    const theme = useTheme();
  
    return (
      <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
          <path
            fill={theme.palette.mode === 'dark' ? 'rgb(18, 28, 45)' : 'white'}
            opacity="1.000000"
            stroke="none"
            d="M24.000088,51.000000 C16.018906,51.000000 8.537701,51.000000 1.028248,51.000000 C1.028248,34.393970 1.028248,17.787889 1.028248,1.090902 C17.563854,1.090902 34.128372,1.090902 50.846443,1.090902 C50.846443,17.665575 50.846443,34.332626 50.846443,51.000000 C42.122471,51.000000 33.311268,51.000000 24.000088,51.000000 M47.301132,2.813050 C39.327606,2.785826 31.353640,2.708507 23.380962,2.778332 C21.973709,2.790657 19.425005,3.255707 19.350113,3.786405 C18.784519,7.794312 18.265942,11.928451 18.784723,15.885241 C18.913548,16.867792 22.874811,17.892128 25.102758,17.959307 C33.911209,18.224905 33.915379,18.086700 33.944401,26.848661 C33.966953,33.655968 34.175983,33.866150 40.960003,33.886158 C41.956779,33.889099 42.957726,33.825584 43.949635,33.894745 C47.710438,34.156975 49.411392,32.602158 49.318066,28.669756 C49.144691,21.364067 49.315182,14.050729 49.220882,6.742146 C49.205078,5.517477 48.576496,4.300715 47.301132,2.813050 M18.061016,29.113047 C17.999199,17.775223 19.850937,17.950686 7.013790,18.123301 C4.186170,18.161324 2.715196,19.434961 2.729056,22.376186 C2.765877,30.189404 2.613441,38.007195 2.876178,45.811722 C2.915864,46.990608 4.742907,49.066761 5.797435,49.101948 C13.933899,49.373428 22.087317,49.361004 30.225492,49.111214 C31.424131,49.074421 33.267796,47.533199 33.629005,46.331997 C36.270882,37.546471 33.639351,34.004051 24.774401,33.944099 C24.109451,33.939602 23.251482,34.205978 22.813835,33.889404 C21.161581,32.694248 19.645897,31.310297 18.061016,29.113047 z"
          />
          <path
            fill="currentColor"
            opacity="1.000000"
            stroke="none"
            d="M47.766098,2.946675 C48.576496,4.300715 49.205078,5.517477 49.220882,6.742146 C49.315182,14.050729 49.144691,21.364067 49.318066,28.669756 C49.411392,32.602158 47.710438,34.156975 43.949635,33.894745 C42.957726,33.825584 41.956779,33.889099 40.960003,33.886158 C34.175983,33.866150 33.966953,33.655968 33.944401,26.848661 C33.915379,18.086700 33.911209,18.224905 25.102758,17.959307 C22.874811,17.892128 18.913548,16.867792 18.784723,15.885241 C18.265942,11.928451 18.784519,7.794312 19.350113,3.786405 C19.425005,3.255707 21.973709,2.790657 23.380962,2.778332 C31.353640,2.708507 39.327606,2.785826 47.766098,2.946675 z"
          />
          <path
            fill="currentColor"
            opacity="1.000000"
            stroke="none"
            d="M18.070053,29.555031 C19.645897,31.310297 21.161581,32.694248 22.813835,33.889404 C23.251482,34.205978 24.109451,33.939602 24.774401,33.944099 C33.639351,34.004051 36.270882,37.546471 33.629005,46.331997 C33.267796,47.533199 31.424131,49.074421 30.225492,49.111214 C22.087317,49.361004 13.933899,49.373428 5.797435,49.101948 C4.742907,49.066761 2.915864,46.990608 2.876178,45.811722 C2.613441,38.007195 2.765877,30.189404 2.729056,22.376186 C2.715196,19.434961 4.186170,18.161324 7.013790,18.123301 C19.850937,17.950686 17.999199,17.775223 18.070053,29.555031 z"
          />
        </svg>
      </SvgIcon>
    );
  };

export const GridIcon = createSvgIcon(
<svg xmlns="http://www.w3.org/2000/svg"
	 width="100%" viewBox="0 0 50 50">
<path fill='pallette.white' opacity="1.000000" stroke="none" 
	d="
M24.000088,51.000000 
	C16.018906,51.000000 8.537701,51.000000 1.028248,51.000000 
	C1.028248,34.393970 1.028248,17.787889 1.028248,1.090902 
	C17.563854,1.090902 34.128372,1.090902 50.846443,1.090902 
	C50.846443,17.665575 50.846443,34.332626 50.846443,51.000000 
	C42.122471,51.000000 33.311268,51.000000 24.000088,51.000000 
M47.301132,2.813050 
	C39.327606,2.785826 31.353640,2.708507 23.380962,2.778332 
	C21.973709,2.790657 19.425005,3.255707 19.350113,3.786405 
	C18.784519,7.794312 18.265942,11.928451 18.784723,15.885241 
	C18.913548,16.867792 22.874811,17.892128 25.102758,17.959307 
	C33.911209,18.224905 33.915379,18.086700 33.944401,26.848661 
	C33.966953,33.655968 34.175983,33.866150 40.960003,33.886158 
	C41.956779,33.889099 42.957726,33.825584 43.949635,33.894745 
	C47.710438,34.156975 49.411392,32.602158 49.318066,28.669756 
	C49.144691,21.364067 49.315182,14.050729 49.220882,6.742146 
	C49.205078,5.517477 48.576496,4.300715 47.301132,2.813050 
M18.061016,29.113047 
	C17.999199,17.775223 19.850937,17.950686 7.013790,18.123301 
	C4.186170,18.161324 2.715196,19.434961 2.729056,22.376186 
	C2.765877,30.189404 2.613441,38.007195 2.876178,45.811722 
	C2.915864,46.990608 4.742907,49.066761 5.797435,49.101948 
	C13.933899,49.373428 22.087317,49.361004 30.225492,49.111214 
	C31.424131,49.074421 33.267796,47.533199 33.629005,46.331997 
	C36.270882,37.546471 33.639351,34.004051 24.774401,33.944099 
	C24.109451,33.939602 23.251482,34.205978 22.813835,33.889404 
	C21.161581,32.694248 19.645897,31.310297 18.061016,29.113047 
z"/>
<path fill="currentColor" opacity="1.000000" stroke="none" 
	d="
M47.766098,2.946675 
	C48.576496,4.300715 49.205078,5.517477 49.220882,6.742146 
	C49.315182,14.050729 49.144691,21.364067 49.318066,28.669756 
	C49.411392,32.602158 47.710438,34.156975 43.949635,33.894745 
	C42.957726,33.825584 41.956779,33.889099 40.960003,33.886158 
	C34.175983,33.866150 33.966953,33.655968 33.944401,26.848661 
	C33.915379,18.086700 33.911209,18.224905 25.102758,17.959307 
	C22.874811,17.892128 18.913548,16.867792 18.784723,15.885241 
	C18.265942,11.928451 18.784519,7.794312 19.350113,3.786405 
	C19.425005,3.255707 21.973709,2.790657 23.380962,2.778332 
	C31.353640,2.708507 39.327606,2.785826 47.766098,2.946675 
z"/>
<path fill="currentColor" opacity="1.000000" stroke="none" 
	d="
M18.070053,29.555031 
	C19.645897,31.310297 21.161581,32.694248 22.813835,33.889404 
	C23.251482,34.205978 24.109451,33.939602 24.774401,33.944099 
	C33.639351,34.004051 36.270882,37.546471 33.629005,46.331997 
	C33.267796,47.533199 31.424131,49.074421 30.225492,49.111214 
	C22.087317,49.361004 13.933899,49.373428 5.797435,49.101948 
	C4.742907,49.066761 2.915864,46.990608 2.876178,45.811722 
	C2.613441,38.007195 2.765877,30.189404 2.729056,22.376186 
	C2.715196,19.434961 4.186170,18.161324 7.013790,18.123301 
	C19.850937,17.950686 17.999199,17.775223 18.070053,29.555031 
z"/>
</svg>
);
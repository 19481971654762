import React, { useState } from "react";
import { CircularProgress, TextField, Button, Select, InputLabel, MenuItem, FormControl } from '@mui/material'
import { useDataProvider, useRedirect } from "react-admin";

const min_chunk_size = 10;
const max_chunk_size = 200;
const default_chunk_size = 50;


export const RouteDifferenceGeneratorForm = (props) => {
  const dataProvider = useDataProvider();
  let [id, setId] = useState(localStorage.getItem("PRD_route_id"));
  let [chunkSize, setChunkSize] = useState(localStorage.getItem("PRD_chunk_size") || default_chunk_size);
  let [chunkingType, setChunkingType] = useState(localStorage.getItem("PRD_chunking_type") || 'two_step');
  let [loading, setLoading] = useState(false);

  const redirect = useRedirect();

  let submitHandler = async (event) => {
    event.preventDefault();
    let valid = true
    setLoading(true);

    if (id == null || id.length == 0) {
      alert(`Please enter route ID`);
      valid = false
    }
    
    if (valid) {
        const {data: response} = await dataProvider.getOne('routes', { id, filter:{ type: 'diff_gen', chunkSize, chunkingType } });
        if(!response || response.length === 0){
          alert(`Request Failed. Route not found.`);
          setLoading(false);
        }
        props.setRouteFromClient(response.geometry);
        props.setBadChunks(response.bad_geometry_chunks);
        props.setRouteId(id);
        setLoading(false);
        redirect("/pluggable_routes/difference_map");
      }
    else {
      setLoading(false);
    }
  }

  let idChange = (event) => {
    setId(event.target.value);
    localStorage.setItem("PRD_route_id", event.target.value)
  }
  let chunkSizeChange = (event) => {
    setChunkSize(event.target.value);
    localStorage.setItem("PRD_chunk_size", event.target.value)
  }

  let formatChunkSizeChange = (event) => {
    var target_chunk_size = event.target.value
    if (isNaN(event.target.value)) target_chunk_size = default_chunk_size
    target_chunk_size = Math.min(max_chunk_size, Math.max(target_chunk_size || default_chunk_size, min_chunk_size))
    
    setChunkSize(target_chunk_size);
    localStorage.setItem("PRD_chunk_size", target_chunk_size)
  }

  let chunkingTypeChange = (event) => {
    setChunkingType(event.target.value);
    localStorage.setItem("PRD_chunking_type", event.target.value)
  }

  const RenderSubmitButton = () => {
    if (loading == false) {
      return (
        <div style={inputStyle}>
          <Button type='submit' variant="contained" color="primary">Send Request</Button>
        </div>
      );
    }
    else {
      return (<div style={{ marginTop: "1rem" }}><CircularProgress />Loading route onto Differences Map</div>);
    }
  }

  const inputStyle = {
    margin: "8px",
    width: "195px"
  }

  return (
    <div >
      <h2>Pluggable Route Difference Generator</h2>
      <p>Find sections along routes which are causing errors</p>
      <p>Submit Route ID: </p>
      <ul>
        <li>Once loading is completed you can view the route and any errors in the Differences Map tab</li>
      </ul>

        <form onSubmit={submitHandler}>
          <div style={inputStyle}>
            <TextField
              variant="outlined"
              label="Route ID"
              value={id}
              size="small"
              onChange={idChange} />
          </div>
          <div style={inputStyle}>
            <TextField
              variant="outlined"
              label="Chunk Size"
              value={chunkSize}
              size="small"
              onChange={chunkSizeChange}
              onBlur={formatChunkSizeChange} />
          </div>
          <FormControl fullWidth style={inputStyle}>
            <InputLabel variant="outlined" id="chunking-type-label">Chunking Type</InputLabel>
            <Select
              labelId="chunking-type-label"
              variant="outlined"
              label="Chunking Type"
              value={chunkingType}
              onChange={chunkingTypeChange}
            >
              <MenuItem value={'default'}>Original</MenuItem>
              <MenuItem value={'binary_search'}>Binary Search</MenuItem>
              <MenuItem value={'two_step'}>Nolan's Two Step</MenuItem>
            </Select>
          </FormControl>
        </form>
      <form onSubmit={submitHandler}>
        {RenderSubmitButton()}
      </form>
    </div>
  );
}
import { InputAdornment, TextField } from "@mui/material";
import { useInput } from "react-admin";

const CurrencyInput = (props) => {
  const { source, label, validate, ...rest } = props;

  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({ source, validate });

  return (
    <TextField
      {...field}
      type="number"
      label={label || source}
      placeholder="0.00"
      error={(isTouched || isSubmitted) && invalid}
      helperText={(isTouched || isSubmitted) && invalid ? error?.message : ""}
      required={isRequired}
      margin="normal"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        onBlur: (e) => {
          e.stopPropagation(); // Prevent unintended blur propagation
          field.onBlur?.(e); // Call original onBlur from react-admin
        },
      }}
      onWheel={(e) => e.target.blur()} // Disable mouse wheel input
      {...rest} // Spread other props safely
    />
  );
};

export default CurrencyInput;

import React, { useEffect, useRef, useState } from "react";
import emptyRedMarker from '../resources/img/marker-empty-red.svg';
import emptyGreenMarker from '../resources/img/marker-empty-green.svg';
import { Button, Title, useDataProvider, useNotify } from "react-admin";
import { Box, Container, styled, useTheme } from "@mui/system";
import { Card, CardContent, Drawer, Slide, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { decode } from "../resources/polyline-decoder";
import { TripPlannerMap } from "./TripPlannerMap";
import { PluggableDebugDoc } from "../documentation/PluggableDebugDoc";
import { sentryErrorHandler } from "../api/SentryErrorHandler";
import { IALoading } from "./IAProgress";
import { CheckCircleOutline, Help, Layers, Streetview } from "@mui/icons-material";
import { CommandDrawer, NoticeDrawer } from "./EnablePatchDrawer";
import { HEREMapLegend, HEREMapSVG } from "./HEREMapLegend";

const HERE_API_KEY = (window.frameElement && window.frameElement.getAttribute("here-map-api-key"))
    ?? (process && process.env && process.env.REACT_APP_HERE_MAP_API_KEY)
    ?? "WhoAreYou?ThisIsABogusKey";

const generateSVG = (type, label) => {
    const baseSVG = (content) => `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">${content}</svg>`;
    const iconContent = {
        start: `
            <path fill="#FF0000" d="M15 3C10.13 3 7 6.13 7 10c0 6.2 7 16 7 16s7-9.8 7-16c0-3.87-3.13-7-7-7z"/>
            <text x="15" y="15" font-size="8pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white" dx="-1">${label}</text>
        `,
        point: `
            <circle cx="12" cy="12" r="8" fill="#2e7dff"/>
            <text x="12" y="12" font-size="6pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white" dy=".3em">${label}</text>
        `
    };
    return baseSVG(iconContent[type]);
};

const MIN_ZOOM_TO_SHOW_MARKERS = 18.75;

const NoticeBox = styled(Box)({
    textAlign: 'center',
    backgroundColor: '#fbcd90',
    color: '#333942',
    padding: 2,
    margin: 2,
    width: '100%',
    maxHeight: '300px',
    overflow: 'auto',
    boxSizing: 'border-box',
});
const PatchBox = styled(Box)({
    backgroundColor: 'yellow',
    padding: 2,
    margin: 2,
    width: '100%',
    maxHeight: '300px',
    overflow: 'auto',
    boxSizing: 'border-box',
});
const ControlBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    transition: 'width 0.3s ease',
    backgroundColor: theme.palette.mode === 'dark' ? '#282a2e' : '#f9f9f9',
    overflow: 'hidden',
    borderRight: '1px solid #ddd'
}));
const SectionCard = styled(Card)(({ theme }) => ({
    cursor: 'pointer',
    width: '20vw',
    padding: '1em',
    borderRadius: '12px',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: theme.palette.mode === 'dark'
            ? '0px 8px 12px rgba(0, 0, 0, 0.8)'
            : '0px 8px 12px rgba(0, 0, 0, 0.2)',
    },
}));
const SectionCardHeader = styled(Typography)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#333333'
}));

// Haversine formula to calculate distance between two coordinates
const getPointsInRange = (lat1, lng1, lat2, lng2) => {
    const toRadians = (degrees) => degrees * (Math.PI / 180);
    const R = 6371; // Radius of the Earth in km
    const deltaLat = toRadians(lat2 - lat1);
    const deltaLng = toRadians(lng2 - lng1);
    const a = Math.sin(deltaLat / 2) ** 2 +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
        Math.sin(deltaLng / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const getBadPoints = (mainPoints, badSectionStart, badSectionEnd) => {
    // Convert mainPoints to lat/lng objects for consistency
    const formattedMainPoints = mainPoints.map(([lat, lng]) => ({ lat, lng }));

    // Calculate the approximate distance of the bad section to set a reasonable buffer
    const sectionDistance = getPointsInRange(
        badSectionStart[0], badSectionStart[1],
        badSectionEnd[0], badSectionEnd[1]
    );

    // Use a buffer distance (e.g., half the section length or 1 km, whichever is smaller)
    const bufferDistance = Math.min(sectionDistance / 2, 1.0); // in km

    // Find all points near the start of the bad section
    const startCandidates = formattedMainPoints
        .map((point, index) => ({
            index,
            distance: getPointsInRange(point.lat, point.lng, badSectionStart[0], badSectionStart[1])
        }))
        .filter(candidate => candidate.distance <= bufferDistance)
        .sort((a, b) => a.distance - b.distance);

    // Find all points near the end of the bad section
    const endCandidates = formattedMainPoints
        .map((point, index) => ({
            index,
            distance: getPointsInRange(point.lat, point.lng, badSectionEnd[0], badSectionEnd[1])
        }))
        .filter(candidate => candidate.distance <= bufferDistance)
        .sort((a, b) => a.distance - b.distance);

    if (!startCandidates.length || !endCandidates.length) {
        return [];
    }

    // Use the closest points as the bounds, but ensure we capture the full range
    const startIndex = Math.min(...startCandidates.map(c => c.index));
    const endIndex = Math.max(...endCandidates.map(c => c.index));

    if (startIndex >= endIndex) {
        return [];
    }

    // Return all original points between these indices (inclusive)
    return mainPoints.slice(startIndex, endIndex + 1);
};

export const HEREMap = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const theme = useTheme();
    const { state } = useLocation() || {};
    const { geometry = null, polylines = null, badChunks = null, snapShotData = null, routeId = null } = state || {};
    //Component States
    const [patchData, setPatchData] = useState([]);
    const [startMarkers, setStartMarkers] = useState([]);
    const [successfulPatches, setSuccessfulPatches] = useState([]);
    const [isImporting, setIsImporting] = useState(false);
    const [originalPointMarkers, setOriginalPointMakers] = useState([]);
    const [pointMarkers, setPointMarkers] = useState([]);
    const [redMarkers, setRedMarkers] = useState([]);
    const [badSectionBounds, setBadSectionBounds] = useState([]);
    const [clickedSectionIndex, setClickedSectionIndex] = useState(null);
    const [mainLine, setMainLine] = useState(null);
    const [isZoomed, setIsZoomed] = useState(false);
    const [applying, setApplying] = useState(false);
    const [showLegend, setShowLegend] = useState(false);
    //Drawer controls
    const [docsExpanded, setDocsExpanded] = useState(false);
    const handleDocExpand = () => { setDocsExpanded(!docsExpanded) };
    const [noticeExpanded, setNoticeExpanded] = useState(false);
    const handleOpenNotices = (e, index) => { e.stopPropagation(); setNoticeExpanded((prev) => ({ ...prev, [index]: true })) };
    const handleCloseNotices = (e, index) => { e.stopPropagation(); setNoticeExpanded((prev) => ({ ...prev, [index]: false })) };
    const [uuidExpand, setUUIDExpand] = useState({});
    const handleOpenUUID = (e, index) => { e.stopPropagation(); setUUIDExpand((prev) => ({ ...prev, [index]: true })) };
    const handleCloseUUID = (e, index) => { e.stopPropagation(); setUUIDExpand((prev) => ({ ...prev, [index]: false })) };
    //References
    const mapRef = React.createRef();
    const uiRef = React.useRef(null);
    const mapInstanceRef = useRef(null)
    const behaviorInstanceRef = useRef(null);

    const initializeMap = () => {
        if (!mapRef.current) return;
        const platform = new H.service.Platform({ apiKey: HERE_API_KEY });
        const engineType = H.Map.EngineType['HARP'];
        const defaultLayers = platform.createDefaultLayers({ engineType });
        const map = new H.Map(
            mapRef.current,
            defaultLayers.vector.normal.logistics, {
            engineType,
            zoom: 4.6,
            pixelRatio: window.devicePixelRatio || 1,
            center: { lat: 39.2011, lng: -98.1285 }
        });
        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        mapInstanceRef.current = map;
        behaviorInstanceRef.current = behavior;
        // add a resize listener to make sure that the map occupies the whole container
        window.addEventListener('resize', () => map.getViewPort().resize());
        // create default UI with layers provided by the platform
        uiRef.current = H.ui.UI.createDefault(map, defaultLayers);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        try {
            const map = mapInstanceRef.current;
            if (!map) {
                initializeMap();
                if (geometry) drawRouteOnMap(geometry);
            }
        } catch (error) {
            notify(`Error initializing map: ${error}`, { type: 'error' });
        }
    }, []);
    //Handle Dark Mode Switch
    useEffect(() => {
        const map = mapInstanceRef.current;
        if (!map) return;
        const platform = new H.service.Platform({ apiKey: HERE_API_KEY });
        const engineType = H.Map.EngineType.HARP;
        const defaultLayers = platform.createDefaultLayers({ engineType });
        if (!defaultLayers.vector || !defaultLayers.vector.normal) {
            sentryErrorHandler(new Error("HEREMaps vector layers are not available."), 'HEREMaps_DarkMode_useEffect');
            return;
        }
        const newBaseLayer = theme.palette.mode === 'dark'
            ? defaultLayers.vector.normal.logisticsnight
            : defaultLayers.vector.normal.logistics;
        if (newBaseLayer) map.setBaseLayer(newBaseLayer);
        else sentryErrorHandler(new Error("Undefined selected base layer."), 'HEREMaps_DarkMode_useEffect');
    }, [theme.palette.mode]);

    const handleClear = () => {
        const map = mapInstanceRef.current
        const behavior = behaviorInstanceRef.current
        setApplying(false);
        setSuccessfulPatches([]);
        // Reset each section in patchData
        setPatchData(patchData.map(section => ({
            ...section,
            toData: section.fromData,
            testReady: false,
            importSuccess: false,
            notices: []
        })));
        setPointMarkers(originalPointMarkers);
        map.getObjects().forEach((obj) => map.removeObject(obj));
        setIsZoomed(prevIsZoomed => {
            const newIsZoomed = !prevIsZoomed;
            map.getViewModel().setLookAtData({ bounds: mainLine.getBoundingBox() });
            return newIsZoomed
        })
        // Reinitialize or ensure behavior is active
        if (!behavior) mapInstanceRef.current.behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        drawRouteOnMap(geometry);
    };

    const updatePatchData = (sectionIndex, latLng, markerIndex) => {
        setPatchData((prevPatchData) => {
            const updatedPatchData = [...prevPatchData];
            const section = updatedPatchData[sectionIndex];
            // Update the specific marker in toData
            section.toData[markerIndex] = { lat: latLng.lat, lng: latLng.lng };
            section.testReady = true;
            section.notices = [];
            return updatedPatchData;
        });
    };

    const handleZoomClick = (index) => {
        const map = mapInstanceRef.current
        setIsZoomed(prevIsZoomed => {
            //Toggle zoomed state
            const newIsZoomed = !prevIsZoomed;
            if (newIsZoomed) {
                map.getViewModel().setLookAtData({ bounds: badSectionBounds[index] });
                pointMarkers.forEach(marker => { marker.setVisibility(true) });
            }
            else {
                map.getViewModel().setLookAtData({ bounds: mainLine.getBoundingBox() });
                pointMarkers.forEach(marker => { marker.setVisibility(false) });
            }
            return newIsZoomed;
        });
    }

    //Handles icon sizing
    const sectionHover = (index, action) => {
        const sectionLetter = String.fromCharCode(65 + index);
        if (action === 'enter') {
            // Create a new larger icon for hover
            const enlargedSVGMarkup = generateSVG('start', sectionLetter, { w: 50, h: 50 }); // Pass size for SVG
            const enlargedIcon = new H.map.Icon(enlargedSVGMarkup, { size: { w: 50, h: 50 } });
            // Update the marker's icon to the larger icon
            redMarkers[index].setIcon(enlargedIcon);
        } else if (action === 'leave') {
            // Reset to the original size
            const originalSVGMarkup = generateSVG('start', sectionLetter, { w: 35, h: 35 }); // Pass size for SVG
            const originalIcon = new H.map.Icon(originalSVGMarkup, { size: { w: 35, h: 35 } });
            // Update the marker's icon to the original size
            redMarkers[index].setIcon(originalIcon);
        }
    };

    //Handles drag event listeners
    const addDragEventListeners = (map, behavior, markerIndexMap) => {
        map.addEventListener('dragstart', (ev) => {
            const target = ev.target;
            const pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
                const targetPosition = map.geoToScreen(target.getGeometry());
                target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
                behavior.disable();
            }
        }, false);
        map.addEventListener('drag', (ev) => {
            const target = ev.target;
            const pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
                target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
            }
        }, false);
        map.addEventListener('dragend', (ev) => {
            const target = ev.target;
            if (target instanceof H.map.Marker) {
                behavior.enable();
                const markerInfo = markerIndexMap.get(target);
                if (markerInfo) {
                    const { sectionIndex, markerIndex } = markerInfo;
                    const newPosition = target.getGeometry();
                    const previousPosition = patchData[sectionIndex]?.fromData[markerIndex];
                    // Only update toData if the position has changed
                    if (newPosition.lat !== previousPosition.lat || newPosition.lng !== previousPosition.lng)
                        updatePatchData(sectionIndex, newPosition, markerIndex)
                }
            }
        }, false);
    };

    const drawMainRoute = (data, hereImport = false, sectionIndex = null) => {
        const map = mapInstanceRef.current
        const mainLineString = new H.geo.LineString();
        data.forEach((point) => { mainLineString.pushLatLngAlt(point.lat, point.lng) });
        const color = !hereImport ? 'blue' : 'green';
        const lineWidth = hereImport ? 8 : 5;
        const mainPolyline = new H.map.Polyline(mainLineString, { style: { strokeColor: color, lineWidth } });
        map.addObject(mainPolyline);
        if (hereImport) return;
        // Add route start marker
        const greenMarkerLocation = { lat: data[0].lat, lng: data[0].lng };
        const greenIcon = new H.map.Icon(emptyGreenMarker, { size: { w: 40, h: 45 } });
        const greenMarker = new H.map.Marker(greenMarkerLocation, { icon: greenIcon });
        map.addObject(greenMarker);

        // Add route end marker
        const redMarkerLocation = { lat: data[data.length - 1].lat, lng: data[data.length - 1].lng };
        const redIcon = new H.map.Icon(emptyRedMarker, { size: { w: 40, h: 45 } });
        const redMarker = new H.map.Marker(redMarkerLocation, { icon: redIcon });
        map.addObject(redMarker);
        setMainLine(mainPolyline);
        // Set view to bounds of the main route.
        map.getViewModel().setLookAtData({ bounds: mainPolyline.getBoundingBox() });
    }

    const drawBadChunks = (map, badChunks, behavior) => {
        badChunks.forEach((section, sectionIndex) => {
            const redLineString = new H.geo.LineString();
            section.forEach(({ lat, lng }) => redLineString.pushLatLngAlt(lat, lng));

            // Draw red polyline for this section
            const redPolyline = new H.map.Polyline(redLineString, {
                style: { strokeColor: 'red', lineWidth: 3 },
            });
            map.addObject(redPolyline);

            // Add start marker for each section with index number
            const sectionLetter = String.fromCharCode(65 + sectionIndex);
            const startMarkerLocation = { lat: section[0].lat, lng: section[0].lng };
            const startIconMarkup = generateSVG('start', sectionLetter);
            const startIcon = new H.map.Icon(startIconMarkup, { size: { w: 35, h: 35 } });
            const startMarker = new H.map.Marker(startMarkerLocation, { icon: startIcon });
            map.addObject(startMarker);

            const markerIndexMap = new Map();

            // Create and add point markers
            section.forEach(({ lat, lng }, markerIndex) => {
                const svgMarkup = generateSVG('point', markerIndex + 1);
                const sectionIcon = new H.map.Icon(svgMarkup, { size: { w: 25, h: 25 } });
                const pointMarker = new H.map.Marker({ lat, lng }, { icon: sectionIcon });
                pointMarker.setVisibility(false);
                pointMarker.draggable = true;
                // Update markerIndexMap
                markerIndexMap.set(pointMarker, { sectionIndex, markerIndex });
                pointMarkers.push(pointMarker);
                // Add drag event listeners
                addDragEventListeners(map, behavior, markerIndexMap);
                map.addObject(pointMarker);
            });
        });
    };

    const drawRouteOnMap = (data) => {
        const map = mapInstanceRef.current
        const behavior = behaviorInstanceRef.current
        try {
            // Clear previous map objects to avoid multiple routes displayed.
            map.getObjects().forEach((obj) => { map.removeObject(obj) });
            if (data) {
                drawMainRoute(data);
                if (badChunks) { drawBadChunks(map, badChunks, behavior) }
                else {
                    const formattedData = data.map(({ lat, lng }) => [lat, lng]);
                    const newBadSectionBounds = [];
                    // Loop through polylines and draw segments (red)
                    for (let i = 0; i < polylines.length - 1; i++) {
                        let sectionMarkers = []
                        const currentLine = decode(polylines[i]).polyline; // Decode current polyline coordinates
                        const nextLine = decode(polylines[i + 1]).polyline; // Decode next polyline coordinates

                        if (currentLine.length > 5) {
                            // Extract last 5 coordinates of currentLine
                            const lastFiveCoords = currentLine.slice(-5);
                            const redLineString = new H.geo.LineString();
                            lastFiveCoords.forEach(([lat, lng]) => {
                                redLineString.pushLatLngAlt(lat, lng);
                            });
                            // Draw red polyline for last 5 coordinates of current segment and the missing ones
                            const redPolyline = new H.map.Polyline(redLineString, {
                                style: { strokeColor: 'red', lineWidth: 6 },
                            });
                            map.addObject(redPolyline);

                            // Add a red marker
                            const sectionLetter = String.fromCharCode(65 + i);
                            const sectionPoint = { lat: lastFiveCoords[0][0], lng: lastFiveCoords[0][1] };  // Create H.geo.Point from lat/lng
                            const svgMarkup = generateSVG('start', sectionLetter)
                            const sectionIcon = new H.map.Icon(svgMarkup, { size: { w: 35, h: 35 } });
                            const redMarker = new H.map.Marker(sectionPoint, { icon: sectionIcon });
                            redMarkers.length === polylines.length - 1 ? redMarkers[i] = redMarker : redMarkers.push(redMarker)
                            map.addObject(redMarker);
                            const zoomPoint = new H.map.Circle(
                                new H.geo.Point(lastFiveCoords[0][0], lastFiveCoords[0][1]),
                                0,
                                { style: { fillColor: '#00FFFFFF' } }
                            );
                            map.addObject(zoomPoint)
                            startMarkers.push(sectionPoint)

                            // Define the bad section endpoints
                            const badSectionStart = lastFiveCoords[4]; // End of current segment
                            const badSectionEnd = nextLine[0]; // Start of next segment

                            // Get the original geometry points within the bad section
                            const missingCoords = getBadPoints(formattedData, badSectionStart, badSectionEnd);

                            const markerIndexMap = new Map();
                            const sectionLineString = new H.geo.LineString();
                            missingCoords.forEach(([lat, lng]) => {
                                sectionLineString.pushLatLngAlt(lat, lng);
                            });

                            // Calculate bounding box as H.geo.Rect
                            const sectionPolyline = new H.map.Polyline(sectionLineString);
                            newBadSectionBounds[i] = sectionPolyline.getBoundingBox();

                            // Add markers for the original bad section points
                            missingCoords.forEach(([lat, lng], index) => {
                                const svgMarkup = generateSVG('point', index + 1);
                                const sectionIcon = new H.map.Icon(svgMarkup, { size: { w: 30, h: 30 } });
                                const pointMarker = new H.map.Marker(
                                    { lat, lng },
                                    { icon: sectionIcon, volatility: true }
                                );
                                pointMarker.setVisibility(false);
                                pointMarker.draggable = true;
                                pointMarker.setData({ sectionIndex: i });
                                pointMarkers.push(pointMarker);
                                sectionMarkers.push({ lat, lng });
                                markerIndexMap.set(pointMarker, { sectionIndex: i, markerIndex: index });
                                map.addObject(pointMarker);
                            });
                            setOriginalPointMakers(pointMarkers)
                            // Add only one set of drag listeners for the map
                            addDragEventListeners(map, behavior, markerIndexMap);
                            patchData.push({ fromData: [...sectionMarkers], toData: [...sectionMarkers], testReady: false, importSuccess: false, notices: [] });
                        }

                        if (nextLine && nextLine.length > 5) {
                            // Extract first 5 coordinates of nextLine
                            const firstFiveCoords = nextLine.slice(0, 5);
                            const redLineString = new H.geo.LineString();
                            firstFiveCoords.forEach(([lat, lng]) => { redLineString.pushLatLngAlt(lat, lng) });

                            // Draw red polyline for first 5 coordinates of next segment
                            const redPolyline = new H.map.Polyline(redLineString, {
                                style: { strokeColor: 'red', lineWidth: 6 },
                            });
                            map.addObject(redPolyline);
                        }
                        if (nextLine) {
                            // Add dashed line (red) between the last point of currentLine and first point of nextLine
                            // This will essentially show the problem area of the pluggable section.
                            const dashedLineString = new H.geo.LineString();
                            dashedLineString.pushLatLngAlt(currentLine[currentLine.length - 1][0], currentLine[currentLine.length - 1][1]);
                            dashedLineString.pushLatLngAlt(nextLine[0][0], nextLine[0][1]);
                            const dashedPolyline = new H.map.Polyline(dashedLineString, {
                                style: { strokeColor: 'red', lineWidth: 6, lineDash: [4, 4] }
                            });
                            map.addObject(dashedPolyline);
                        }
                    }
                    setBadSectionBounds(newBadSectionBounds);
                }
            }
        } catch (error) {
            notify(`Error drawing route on map: ${error}`, { type: error })
        }
    };

    const handleImport = async (e, sectionIndex) => {
        e.stopPropagation();
        if (isImporting) return; // Prevent multiple concurrent imports
        setIsImporting(true);
        const sectionLetter = String.fromCharCode(65 + sectionIndex);
        try {
            const section = patchData[sectionIndex];
            const { data, error } = await dataProvider.update('here_call', { id: sectionIndex, data: section.toData });
            if (error) throw error;
            if (data?.notices) {
                notify(`HERE import returned notices for section ${sectionLetter}.`, { type: 'warning' })
                setPatchData((prevPatchData) => {
                    const updatedPatchData = [...prevPatchData];
                    const section = updatedPatchData[sectionIndex];
                    section.notices = data.notices;
                    return updatedPatchData;
                });
            } else {
                notify(`Succesfully imported section ${sectionLetter} to HERE.`, { type: 'success' })
                setPatchData((prevPatchData) => {
                    const updatedPatchData = [...prevPatchData];
                    const section = updatedPatchData[sectionIndex];
                    section.importSuccess = true;
                    return updatedPatchData;
                });
                data.routes.flatMap((route) => route.sections.map((section) => section.polyline))
                    .forEach((line) => {
                        const formattedGeometry = decode(line).polyline.map(([lat, lng]) => ({ lat, lng }));
                        drawMainRoute(formattedGeometry, true, sectionIndex);
                    });
            }
        } catch (error) {
            notify(`Error importing geometry for section ${sectionLetter}: ${error}`, { type: 'error' });
            return null;
        } finally {
            setIsImporting(false);
        }
    };

    const transformCoordinates = (coordinates) => {
        return coordinates.map(({ lat, lng }) => ({
            latitude: lat,
            longitude: lng
        }));
    };

    const handleApplyPatch = async (e, sectionIndex, section) => {
        e.stopPropagation();
        const map = mapInstanceRef.current;
        const sectionLetter = String.fromCharCode(65 + sectionIndex);
        setApplying(true);
        notify('Please wait... Applying pluggable patch for the route', { type: 'info' });
        try {
            const patchBody = {
                from: transformCoordinates(section.fromData),
                to: transformCoordinates(section.toData)
            };
            const { data } = await dataProvider.create('patches', { id: routeId, body: patchBody });
            setSuccessfulPatches(prev => [
                ...prev.filter(patch => patch.section !== sectionIndex),
                {
                    section: sectionIndex,
                    uuid: data.id,
                    command: `sploot "/app/squid/bin/squid rpc 'IO.inspect(SquidFortress.Services.PluggablePatcher.mark_patch_as_enabled(\"${data.id}\") |> then(fn res -> SquidFortress.Services.PluggablePatcher.reload() end))'"`
                }
            ]);
            setPatchData((prevPatchData) => {
                const updatedPatchData = [...prevPatchData];
                const section = updatedPatchData[sectionIndex];
                section.testReady = false;
                section.importSuccess = false;
                return updatedPatchData;
            });
            notify(`Successfully applied patch for section: ${sectionLetter}`, { type: 'success' });
        } catch (error) {
            sentryErrorHandler(error, 'patches');
            notify('Error applying pluggable patch. Check Sentry.', { type: 'error' });
        } finally {
            setApplying(false);
            handleZoomClick(sectionIndex);
        }
    };

    const BadSectionsCards = ({ patchData, handleApplyPatch }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '1rem' }}>
                {patchData.map((section, index) => {
                    const sectionLetter = String.fromCharCode(65 + index);
                    const successfulPatch = successfulPatches.find(patch => patch.section === index);
                    return (
                        <SectionCard key={index} onMouseEnter={() => sectionHover(index, 'enter')} onMouseLeave={() => sectionHover(index, 'leave')} onClick={() => handleZoomClick(index)}>
                            <CardContent>
                                <SectionCardHeader>Section {sectionLetter}<span>{HEREMapSVG('start', sectionLetter)}</span></SectionCardHeader>
                                <hr style={{ margin: '1em 0', border: 'none', borderTop: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#ccc'}` }} />
                                {section.testReady && (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {section.importSuccess
                                            ? <Typography sx={{ display: 'flex', color: theme.palette.success.light, marginBottom: '1em' }}><CheckCircleOutline sx={{ marginRight: '0.5em' }} /> Successfully tested patch for section </Typography>
                                            : <Button label="Test Section" fullWidth variant="contained" onClick={(e) => handleImport(e, index)} sx={{ marginBottom: '1em', fontWeight: 'bold' }} />
                                        }
                                    </div>
                                )}
                                {section.importSuccess &&
                                    <Button label="Apply Patch To Section" fullWidth variant="contained" disabled={!!(applying || successfulPatch)} onClick={(e) => handleApplyPatch(e, index, section)} sx={{ marginBottom: '1em', fontWeight: 'bold' }} />
                                }
                                {successfulPatch && (
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <div style={{ textAlign: 'center', marginBottom: '1em' }}>
                                            <Typography sx={{ display: 'inline-flex', alignItems: 'center', color: theme.palette.success.light }}><CheckCircleOutline sx={{ marginRight: '0.5em' }} />Successfully tested patch for section </Typography>
                                            <Typography sx={{ display: 'inline-flex', alignItems: 'center', color: theme.palette.success.light }}><CheckCircleOutline sx={{ marginRight: '0.5em' }} />Successfully applied patch to section</Typography>
                                        </div>
                                        <PatchBox>
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>IMPORTANT!</Typography>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                    This patch is NOT enabled!
                                                </Typography>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                    Please escalate this command to engineering for patch enabling.
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    Any additional patches made to this section will override this one.
                                                </Typography>
                                            </div>
                                            <hr style={{ border: '1px solid black', width: '100%' }} />
                                            <Button fullWidth variant="contained" onClick={(e) => handleOpenUUID(e, index)}>
                                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="body2" style={{ textDecoration: 'underline' }}>View Command for UUID:</Typography>
                                                    <Typography variant="body2">{successfulPatch.uuid}</Typography>
                                                </Box>
                                            </Button>
                                        </PatchBox>
                                        <CommandDrawer uuidExpand={uuidExpand[index] || false} handleCloseUUID={(e) => handleCloseUUID(e, index)} patchDetails={successfulPatch} />
                                    </div>
                                )}

                                {section.notices.length > 0 && (
                                    <div>
                                        <NoticeBox>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}> Import Failed: HERE returned notices.</Typography>
                                            <Button fullWidth color="warning" variant="contained" onClick={(e) => handleOpenNotices(e, index)} label="View Notices" />
                                        </NoticeBox>
                                        <NoticeDrawer noticeExpanded={noticeExpanded[index] || false} handleCloseNotices={(e) => handleCloseNotices(e, index)} notices={section.notices} />
                                    </div>
                                )}
                                <Button sx={{
                                    fontWeight: 'bold', marginTop: '1em', border: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#ccc'}`,
                                    '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5' },
                                }} label="Street View" fullWidth startIcon={<Streetview />} variant="outlined" color="primary"
                                    onClick={() => window.open(`https://maps.google.com/maps?q=&layer=c&cbll=${startMarkers[index].lat},${startMarkers[index].lng}`)}
                                />
                            </CardContent>
                        </SectionCard>
                    );
                })}
            </div>
        );
    };

    return snapShotData ? (
        <div style={{ margin: '2em' }}>
            <Title title="NTPS" />
            <TripPlannerMap snapShotData={snapShotData} />
        </div>
    ) : (
        <Container style={{ display: 'contents', height: '100%' }}>
            <Box id="caution-box" sx={{ width: '100vw', backgroundColor: 'warning.main', color: theme.palette.mode === 'dark' ? 'black' : 'white', textAlign: 'center', padding: '0.5em', fontWeight: 'bold' }}>⚠️ Please read the Guide carefully before using the debugger! ⚠️</Box>
            <div style={{ height: '100vh', display: 'flex', flexDirection: 'row' }}>
                <Title title="Pluggable Routing Debugger" />
                <ControlBox id="control-panel-pane" sx={{ overflow: "scroll", overflowY: "auto", display: 'flex', flexDirection: 'column', width: '25%', height: '100%', padding: (docsExpanded || uuidExpand) ? '1em' : '0.5em' }}>
                    <Drawer
                        anchor="right"
                        open={docsExpanded}
                        onClose={handleDocExpand}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: '60%',
                                padding: '1em',
                                backgroundColor: theme.palette.mode === 'dark' ? '#0b0e12' : '#f9f9f9',
                            },
                        }}
                    >
                        <PluggableDebugDoc sx={{ backgroundColor: theme.palette.background.default }} />
                    </Drawer>
                    <Box id="control-panel" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, marginTop: '1em' }}>
                        <Typography variant="h4"><b>Control Panel</b></Typography>
                        {applying ? <IALoading /> : <div></div>}
                        <Button fullWidth onClick={handleDocExpand} variant="contained" label="Guide" startIcon={<Help />} />
                        <Button fullWidth onClick={() => setShowLegend(!showLegend)} variant="contained" label="Map Legend" startIcon={<Layers />} />
                        <Slide in={showLegend} direction="right" mountOnEnter unmountOnExit><div><HEREMapLegend /></div></Slide>
                        <Button fullWidth disabled={isImporting} variant="outlined" onClick={handleClear} label="Clear Changes" />
                        <hr style={{ border: theme.palette.mode === 'dark' ? '1px solid white' : "1px solid black", width: "100%" }} />
                        <Typography variant="h5"><b>Bad Sections</b></Typography>
                        <Slide direction="up" in={patchData.length > 0} easing={theme.transitions.easing.easeOut}>
                            <div>
                                <BadSectionsCards patchData={patchData} handleApplyPatch={handleApplyPatch} />
                            </div>
                        </Slide>
                    </Box>
                </ControlBox>
                <Box id="map-pane" style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, boxSizing: 'border-box', height: '100%' }}>
                    <div
                        id="map-container"
                        ref={mapRef}
                        style={{
                            flexGrow: 1,
                            position: 'relative',
                            boxSizing: 'border-box',
                            flexBasis: '1px',
                            overflow: "hidden",
                            touchAction: "none",
                            userSelect: "none",
                            height: '100%'
                        }}
                    />
                </Box>
            </div>
        </Container>
    )
}
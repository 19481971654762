import React, { useEffect, useState } from "react";
import {
  Create, DateField, SimpleForm, List, Datagrid, TextField, TextInput,
  ReferenceManyField, SelectInput, SingleFieldList, ChipField, required, Show,
  usePermissions, TopToolbar, Button, EditButton,
  useShowController, TabbedShowLayoutTabs, TabbedShowLayout, Tab, useRedirect,
  Toolbar, SaveButton, DateInput, useUpdate, useGetManyReference, FunctionField,
  useRefresh, useNotify,
  ReferenceInput,
  AutocompleteInput,
  CreateButton,
  ReferenceField
} from "react-admin";
import { Box, Popover, Alert, AlertTitle, Card, CardContent, Typography, Grid, InputLabel, Chip } from "@mui/material";
import { color, styled, useTheme } from "@mui/system";
import { BillingAccountCreateDoc } from "../documentation/CreateBillingAccountDoc"
import { renderIfPermissions } from "../api/permissionManager";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Add, CheckBox, CheckBoxOutlineBlank, KeyboardArrowUp } from "@mui/icons-material";
import EmptyComponent from "../components/EmptyComponent";
import CustomAccountContractCreateDoc from "../documentation/CreateCustomAccountContractDoc";

const CancelButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-outlinedSecondary': {
    border: '1px solid gray',
    color: 'gray',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
    margin: '2em',
  },
}));

const BillingAccountShowActions = ({ id, name }) => {
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  return (
    <Box sx={{ padding:'1em', mt: 2, display: 'flex', justifyContent: 'flex-start', backgroundColor:'#f5f5f5'}}>
      {renderIfPermissions(permissions, "billing", "write",
        <Button
          variant="contained"
          startIcon={<Add />}
          label="Add"
          onClick={() => navigate(`/billing_contract/${id}`, { state: { id, name } })}
        />
      )}
    </Box>
  );
};


export const BillingAccountFilter = [
  <TextInput label="Search by name" source="name" alwaysOn />
]

export const BillingAccountList = () => {
  return (
    <List pagination={false} exporter={false} filters={BillingAccountFilter}>
      <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
        <TextField source="name" sortable={false} />
        <TextField source="billing_account_type" label="Type" sortable={false} />
        <DateField source="contract_anniversary" sortable={false} options={{ timeZone: 'UTC' }} />
        <DateField source="valid_from" sortable={false} options={{ timeZone: 'UTC' }} />
        <TextField source="price_sheet_code" sortable={false} />
      </Datagrid>
    </List>
  );
}

const AccountContractUpdate = ({ id, contract, contractList, handleSetActiveContracts }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { isPending }] = useUpdate();

  const getLastDayOfPrevMonth = () => {
    const now = new Date();
    const lastDay = new Date(now.getFullYear(), now.getMonth(), 0);
    return new Date(lastDay).toLocaleDateString();
  };
  const findActiveContracts = (contracts) => {
    const activeContracts = contracts.filter(contract => contract.active);
    return activeContracts;
  }

  const handleClick = () => {
    let activeContracts = findActiveContracts(contractList)
    const alreadyActive = activeContracts.length > 0;
    const contractIsActive = contract.active;

    if (alreadyActive && !contractIsActive) {
      notify(
        'Please deselect the currently active contract before activating another.',
        { type: 'error' }
      );
      return;
    }

    const updatedContract = {
      ...contract,
      active: !contractIsActive,
      valid_to: contractIsActive ? new Date(getLastDayOfPrevMonth()).toISOString().slice(0, 10) : null,
      valid_from: new Date(contract.valid_from).toISOString().slice(0, 10),
    };

    update(
      'account_contracts',
      { id: id, data: updatedContract },
      {
        onSuccess: () => {
          refresh();
          notify(`Account contract ${id} successfully updated`, 'success');
          if (!contractIsActive) {
            handleSetActiveContracts([updatedContract]);
          } else {
            handleSetActiveContracts([]);
          }
        },
        onError: (error) => {
          notify(`Error updating account contract: ${JSON.stringify(error)}`, { type: 'error' });
        }
      }
    );
  };
  return (
    <Button disabled={isPending} onClick={handleClick} startIcon={contract.active ? <CheckBox /> : <CheckBoxOutlineBlank />} />
  );
};

export const BillingAccountShow = (props) => {
  const { id } = useParams();
  const { permissions } = usePermissions();
  const theme = useTheme();
  const navigate = useNavigate();
  const redirect = useRedirect();
  const { record } = useShowController();
  const [acctType, setAcctType] = useState();
  const [acctName, setAcctName] = useState();
  const [contracts, setContracts] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [activeContracts, setActiveContracts] = useState([]);

  //Check for correct permissions to get account_contracts.
  const permissionCheck = (resource, requiredPermission) => {
    if (localStorage.getItem('granular_permissions')) {
      const resourcePerms = (permissions instanceof Map) ? permissions.get(resource) : permissions[resource];
      if (resourcePerms === undefined || resourcePerms === null) return false;
      return resourcePerms[requiredPermission];
    }
    else if(permissions !== undefined && permissions.billing === 'full') return true
    else return false
  };
  const hasBillingReadPermission = permissionCheck('billing', 'read_only');
  const { data: contractData, isLoading, error: listError } = hasBillingReadPermission
    ? useGetManyReference(
        'account_contracts',
        { target: 'billing_account_id', id: id }
      )
    : { data: [], isLoading: false, error: null };

  useEffect(() => {
    if (record) {
      setAcctType(record.billing_account_type);
      setAcctName(record.name);
    }
    if (!isLoading && !listError) {
      setContracts(contractData);
      findActiveContracts();
    }
  }, [record, contractData, isLoading, listError]);

  const findActiveContracts = () => {
    const activeContracts = contracts.filter(contract => contract.active);
    setActiveContracts(activeContracts);
  }

  const handleSetActiveContracts = (contracts) => {
    setActiveContracts(contracts);
  }

  const handleCloseAlert = () => {
    setAlertSeverity(null);
    setAlertMessage('');
  };

  const handleCreateButtonClick = () => {
    const name = acctName
    navigate(`/billing_contract/${record.id}`, { state: { id, name } });
  };

  const handleRedirect = (field, value) => {
    if (field === 'contract_structure_code') {
      redirect('show', 'contract_structures', value);
    } else if (field === 'price_sheet_code') {
      redirect('show', 'price_sheets', value);
    }
  };

  return (
    <Show {...props}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />}
        spacing={2}
        style={{ paddingBottom: '16px' }}
        syncWithLocation={false}
      >
        <Tab label="Billing Account Information" path="info">
          <Box gridColumn="span 9" sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)", marginBottom: "10px" }}>
            <Typography variant="h5" sx={{ paddingBottom: "16px" }}>{acctName}</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel shrink sx={{ color: 'text.secondary' }}>Account Type</InputLabel>
              <TextField source="billing_account_type" label="Billing Account Type" />
            </Grid>
            <Grid item xs={12}>
              <InputLabel shrink sx={{ color: 'text.secondary' }}>External ID</InputLabel>
              <TextField source="external_id" label="External ID" />
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink sx={{ color: 'text.secondary' }}>Valid From</InputLabel>
              <DateField source="valid_from" label="Valid From" options={{ timeZone: 'UTC' }} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel shrink sx={{ color: 'text.secondary' }}>Valid To</InputLabel>
              <DateField source="contract_anniversary" label="Contract Anniversary" options={{ timeZone: 'UTC' }} />
            </Grid>
          </Grid>

          {renderIfPermissions(permissions, "billing", "read_only",
            <Card sx={{ padding: 2, height: "100%" }}>
              <Grid container spacing={2} sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)", }}>
                <Grid item xs={6} sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.2)" }}>
                  <Typography variant="h6" sx={{ marginBottom: 0.5 }}>
                    <u>Contract Structure</u>
                  </Typography>
                  <ReferenceField source="contract_structure_code" reference="contract_structures">
                    <CardContent>
                      <Typography>Name: <TextField source="name" /></Typography>
                      <Typography>Created At: <DateField source="created_at" options={{ timeZone: 'UTC' }} /></Typography>
                      <Typography>
                        Code:
                        <FunctionField
                          render={(record) => (
                            <Button
                              label={record.code}
                              onClick={() => redirect('show', 'contract_structures', record.code)}
                            />
                          )}
                        />
                      </Typography>
                    </CardContent>
                  </ReferenceField>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ marginBottom: 0.5 }}>
                    <u>Price Sheet</u>
                  </Typography>
                  <ReferenceField source="price_sheet_code" reference="price_sheets">
                    <CardContent>
                      <Typography>Description: <TextField source="description" /></Typography>
                      <Typography>Created At: <DateField source="created_at" options={{ timeZone: 'UTC' }} /></Typography>
                      <Typography>
                        Code:
                        <FunctionField
                          render={(record) => (
                            <Button
                              label={record.code}
                              onClick={() => redirect('show', 'price_sheets', record.code)}
                            />
                          )}
                        />
                      </Typography>
                    </CardContent>
                  </ReferenceField>
                </Grid>
              </Grid>

              <CardContent sx={{ marginTop: 2 }}>
                <Typography variant="h6" sx={{ marginBottom: 0.5 }}><u>Associated Products</u></Typography>
                <ReferenceField source="contract_structure_code" reference="contract_structures">
                  <FunctionField
                    render={(record) =>
                      record && (
                        <ReferenceManyField label="Associated Products" reference="contract_structure_products" target="contract_structure_code" filter={{ id: record.code }}>
                          <SingleFieldList linkType={false}>
                            <FunctionField
                              render={(productRecord) => (
                                <Chip
                                  label={productRecord.product_code}
                                  onClick={() => redirect('show', 'products', productRecord.product_code)}
                                  sx={{ cursor: 'pointer', color: theme.palette.primary.main, fontSize: '0.8125rem' }}
                                />
                              )}
                            />
                          </SingleFieldList>
                        </ReferenceManyField>
                      )
                    }
                  />
                </ReferenceField>
              </CardContent>
            </Card>
          )}

          {renderIfPermissions(permissions, "clients", "read_only",
            <Card>
              <CardContent>
                <Typography variant="h6" sx={{ marginBottom: 0.5 }}><u>Associated Clients</u></Typography>
                <ReferenceManyField label="Associated Clients" reference="clients" target="billing_account_id">
                  <SingleFieldList linkType="show">
                    <ChipField source="key" />
                  </SingleFieldList>
                </ReferenceManyField>
              </CardContent>
            </Card>
          )
          }
        </Tab >

        {
          renderIfPermissions(permissions, "billing", "read_only",
            <Tab label="Account Contracts" path="account_contracts" >
              <Box width="fit-content">
                {alertSeverity && (
                  <Alert severity={alertSeverity} onClose={handleCloseAlert}>
                    <AlertTitle>{alertSeverity === 'success' ? 'Success' : 'Error'}</AlertTitle>
                    {alertMessage}
                  </Alert>
                )}
              </Box>
              <ReferenceManyField label="Account Contracts" reference="account_contracts" target="billing_account_id" record={record}>
                <Datagrid bulkActionButtons={false} empty={<EmptyComponent permissionResource="billing" label={"Account Contracts"} onClick={handleCreateButtonClick} />}>
                  {renderIfPermissions(permissions, "billing", "write",
                    <FunctionField
                      label="Active?"
                      render={(record) => (
                        <AccountContractUpdate
                          id={record.id}
                          contract={record}
                          contractList={contracts}
                          handleSetActiveContracts={handleSetActiveContracts}
                        />
                      )}
                    />)}
                  <FunctionField
                    label="Contract Structure Code"
                    render={(record) => (
                      <Button
                        label={record.contract_structure_code}
                        onClick={() => handleRedirect('contract_structure_code', record.contract_structure_code)}
                      />
                    )}
                  />
                  <FunctionField
                    label="Price Sheet Code"
                    render={(record) => (
                      <Button
                        label={record.price_sheet_code}
                        onClick={() => handleRedirect('price_sheet_code', record.price_sheet_code)}
                      />
                    )}
                  />
                  <TextField source="created_by" />
                  <DateField source="created_at" />
                  <DateField source="valid_from" />
                  <DateField source="valid_to" />
                  <DateField source="contract_anniversary" />
                </Datagrid>
              </ReferenceManyField>
              {contracts.length > 0 && (
                <BillingAccountShowActions id={id} name={acctName} acct_type={acctType} />
              )}
            </Tab >
          )}
      </TabbedShowLayout >
    </Show >
  );
};

const CustomAccountContractCreateToolbar = ({ id }) => {
  const redirect = useRedirect();
  const handleCancel = () => {
    redirect(`/billing_accounts/${id}/show`);
  };
  return (
    <Toolbar>
      <SaveButton label="Save" />
      <CancelButton
        variant="outlined"
        onClick={handleCancel}
        color="secondary"
        label="Cancel"
      />
    </Toolbar>
  )
}

export const CustomAccountContractCreate = ({ id, name }) => {
  return (
    <Create
      resource="account_contracts"
      redirect={`/billing_accounts/${id}/show`}
      aside={<CustomAccountContractCreateDoc />}
      title={"Create Custom Account Contract"}
    >
      <SimpleForm toolbar={<CustomAccountContractCreateToolbar id={id} />}>
        <TextInput
          source="billing_account_external_id"
          label="Billing Account External ID"
          validate={required()}
          defaultValue={id}
          disabled
          fullWidth
        />
        <TextInput
          source="name"
          validate={required()}
          defaultValue={name}
          disabled
          fullWidth
        />
        <ReferenceInput source="contract_structure_code" reference='contract_structures'>
          <AutocompleteInput fullWidth optionText="code" validate={required()} />
        </ReferenceInput>
        <ReferenceInput source="price_sheet_code" reference='price_sheets'>
          <AutocompleteInput fullWidth optionText="code" validate={required()} />
        </ReferenceInput>
        <DateInput source="valid_from" validate={required()} fullWidth />
        <DateInput source="contract_anniversary" validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  );
};

export const BillingAccountCreate = () => {
  return (
    <Create resource="billing_accounts_v2"
      redirect={'/billing_accounts'}
      title="Create Billing Account"
      aside={<BillingAccountCreateDoc />}
    >
      <SimpleForm>
        <TextInput
          fullWidth
          source="name"
          label="Billing Account Name"
          validate={required()}
        />
        <SelectInput
          fullWidth
          source="type"
          validate={required()}
          choices={[
            { id: 'house_account', name: 'House Account' },
            { id: 'reseller', name: 'Reseller' },
          ]}
        />
        <TextInput
          fullWidth
          source="hubspot_company_identifier"
          label="HubSpot Company Identifier"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
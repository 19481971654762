import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_DSN = (process && process.env && process.env.REACT_APP_SENTRY_DSN);
const EVIRONMENT = (process && process.env && process.env.REACT_APP_ENVIRONMENT);

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: EVIRONMENT
});
// Custom logic to capture console logs
const originalConsoleError = console.error;
const originalConsoleWarn = console.warn;

// Capture `console.error`
console.error = (...args) => {
  Sentry.captureMessage(args.join(" "), "error");
  originalConsoleError.apply(console, args);
};

// Capture `console.warn`
console.warn = (...args) => {
  Sentry.captureMessage(args.join(" "), "warning");
  originalConsoleWarn.apply(console, args);
};
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';
import { useDataProvider, useGetList } from 'react-admin';
import { sentryErrorHandler } from '../api/SentryErrorHandler';

export const ErrorReportForm = () => {
    const dataProvider = useDataProvider();
    const [username, setUsername] = useState('');
    const [clientkey, setClientkey] = useState('');
    const [requestList, setRequestList] = useState(false);
    const [unsortedListData, setData] = useState([]);
    const [reportuuid, setReportuuid] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedField, setSortedField] = useState('report_time');
    const [noReports, setNoReports] = useState(false);

    const { data, error, isLoading } = useGetList('issue_report', { id: clientkey, meta: { username, client_key: clientkey } }, { enabled: requestList });

    useEffect(() => {
        if (requestList && !isLoading) {
            if (error) {
                if (error.status == 404) {
                    setData([])
                    alert("Invalid Client Key or Username")
                }
            } else if (data && data.length > 0) {
                setData(data);
                setNoReports(false);
            } else {
                setData([]);
                setNoReports(true);
            }
            setRequestList(false);
        }
    }, [requestList, data, error, isLoading]);

    const downloadLogFile = async (reportUrl, uuid) => {
        try {
            const { data: downloadData } = await dataProvider.getOne('issue_report_download', { url: reportUrl })
            const text = JSON.stringify(downloadData);
            const jsonData = JSON.parse(text);

            // Extract application logs from JSON data
            let { application_logs } = jsonData;
            // Remove the application_logs from jsonData so it doesn't get repeated
            delete jsonData.application_logs;

            const formattedJson = JSON.stringify(jsonData, null, 2);

            // Add new lines before and after "--------- beginning of main"
            application_logs = application_logs.replace(
                "--------- beginning of main",
                "--------- beginning of main\n"
            ).replace(
                "--------- beginning of system",
                "\n--------- beginning of system\n"
            );

            // Replace \n with actual newlines in the logs
            const formattedLogs = application_logs.replace(/\\n/g, '\n');

            // Combine the formatted JSON and the formatted logs
            const combinedFormattedText = `${formattedJson}\n\n${formattedLogs}`;
            const blob = new Blob([combinedFormattedText], { type: 'text/plain' });
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${clientkey}-${uuid}-${username}.log`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            /*
                The data provider will catch an error and report to sentry.
                However, it won't catch or send any errors after getting the required data.
                So, we will send any edge case errors to Sentry ourselves. 
            */
            sentryErrorHandler(error, 'downloadLogFile')
        }
    };

    const submitHandler = (event) => {
        event.preventDefault();
        if (!clientkey || !username) {
            alert("Both Client Key and Username are required");
            return;
        }
        setRequestList(true);
    };

    const downloadReportbutton = async (uuid) => {
        setReportuuid(uuid);
        const {data} = await dataProvider.getOne('issue_report', { id: uuid });
        const reportData = data;
        if(reportData) downloadLogFile(reportData.url, uuid)
    };

    const changeSortOrder = (field) => {
        const isAsc = sortedField === field && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortedField(field);
    };

    const sortedListData = [...unsortedListData].sort((a, b) => {
        return sortDirection === 'asc'
            ? new Date(a[sortedField]) - new Date(b[sortedField])
            : new Date(b[sortedField]) - new Date(a[sortedField]);
    });

    return (
        <div>
            <h2>Error Report Tool</h2>
            <p>Here you can enter a client key and a Username, and receive a list of related error reports</p>
            <Container>
                <form onSubmit={submitHandler}>
                    <div style={{ margin: '8px' }}>
                        <TextField
                            id='clientKey-input'
                            variant="outlined"
                            label="Client Key"
                            name='clientKey'
                            size="small"
                            value={clientkey}
                            onChange={(e) => setClientkey(e.target.value)}
                        />
                    </div>
                    <div style={{ margin: '8px' }}>
                        <TextField
                            id='username-input'
                            variant="outlined"
                            label="Username"
                            name='username'
                            size="small"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div style={{ margin: '8px' }}>
                        <Button type='submit' variant="contained" color="primary" disabled={isLoading}>
                            {isLoading ? 'Loading...' : 'Send Request'}
                        </Button>
                    </div>
                </form>
            </Container>
            {noReports && <p>No error reports found for the provided client key and username.</p>}
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortedField === 'report_time'}
                                    direction={sortDirection}
                                    onClick={() => changeSortOrder('report_time')}
                                >
                                    Report Time
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>UUID</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedListData.map((reports) => (
                            <TableRow key={reports.uuid}>
                                <TableCell>{reports.report_time}</TableCell>
                                <TableCell>{reports.uuid}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" onClick={() => downloadReportbutton(reports.uuid)}>
                                        Open Report
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
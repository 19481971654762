import React, { useEffect, useState } from 'react';
import { List, Datagrid, TextField, useNotify, useDataProvider, TextInput, SimpleForm } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardActions, Typography, Box, Paper, Dialog, DialogActions, Button, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { sentryErrorHandler } from '../api/SentryErrorHandler';


export const CustomReleaseCreate = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const dataProvider = useDataProvider();


  const handleSubmit = async (data) => {
      await dataProvider.create('app_releases', { data }).then(({ response}) => {
        notify('Release Created Successfully', { type: 'success' });
        navigate(`/app_releases/`);
      }
      ).catch(error =>  {
        notify('Error creating release', { type: 'error' });
      })
  }

  return (
    <Paper elevation={3} style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h5" gutterBottom>Create New Release</Typography>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput label="Release Name" source="release_name" fullWidth />
        <TextInput label="iOS Tag" source="ios_tag" fullWidth />
        <TextInput label="Android Tag" source="android_tag" fullWidth />
      </SimpleForm>
    </Paper>
  )
}

export const CustomReleaseShow = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  const record = location.state?.record;
  const notify = useNotify();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleEdit = () => {
    navigate(`../app_releases/${record.id}/edit`, { state: { record }, replace: true });
  };
  const handleDelete = async () => {
      await dataProvider.delete('app_releases', { id: record.id }).then(({ response }) => {
        notify(`Release "${record.id}" deleted successfully`, { type: 'success' })
        navigate('/app_releases');
      }).catch((error) => {
      notify('Error deleting release', { type: 'error' })
    });
  };


  return (
    <Paper elevation={3} sx={{ margin: '16px', padding: '16px' }}>
      <Typography><strong>ID:</strong> {record.id}</Typography>
      <Typography><strong>iOS Tag:</strong> {record.ios_tag || 'Null'}</Typography>
      <Typography><strong>Android Tag:</strong> {record.android_tag || 'Null'}</Typography>
      <Typography><strong>Created At:</strong> {record.created_at}</Typography>

      <CardActions sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, padding: '8px 0 0 0', marginTop: '3px' }}>
        <Button variant="contained" color="primary" onClick={handleEdit} sx={{ minWidth: 50 }}>
          Edit
        </Button>
        <Button variant="contained" color="error" onClick={handleOpenDeleteDialog} sx={{ minWidth: 50 }}>
          Delete
        </Button>
      </CardActions>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Tag?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the "<strong>{record.id}</strong>" release?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>

  );
};

export const CustomReleaseEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const record = location.state?.record;

  if (!record) {
    return (
      <Paper elevation={3} style={{ padding: '16px' }}>
        <Typography>No record found</Typography>
        <Button onClick={() => navigate(-1)}>Go Back</Button>
      </Paper>
    );
  }

  const handleSubmit = async (data) => {
      await dataProvider.update('app_releases', {
        id: record.id,
        data,
        previousData: record,
      }).then (({ response }) => {
        notify('Release updated successfully', { type: 'success' });
        navigate(`/app_releases/${record.id}/show`, { state: { record: { ...record, ...data } } });
      }).catch( (error) => {
        notify('Error updating release', { type: 'error' });
    })
  };

  return (
    <Paper elevation={3} style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h5" gutterBottom>Edit Release</Typography>

      <SimpleForm onSubmit={handleSubmit} defaultValues={record}>
        <TextInput disabled label="Release Name" source="release_name" fullWidth />
        <TextInput label="iOS Tag" source="ios_tag" fullWidth />
        <TextInput label="Android Tag" source="android_tag" fullWidth />
      </SimpleForm>
    </Paper>
  );
};

export const CustomReleaseList = (props) => {
  const navigate = useNavigate();

  const handleRowClick = (id, record) => {
    navigate(`/app_releases/${id}/show`, { state: { record } });
  };

  const handleCreate = () => {
    navigate(`../create`)
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        <Typography variant="h5" sx={{ paddingTop: '10px', paddingLeft: '10px' }} >App Releases</Typography>
        <Button variant="contained" color="primary" onClick={handleCreate}>Create New Release</Button>
      </Box>
      <List {...props} pagination={false} exporter={false} actions={false}>
        <Datagrid rowClick={(id, resource, record) => { handleRowClick(id, record); }} bulkActionButtons={false} size="medium" >
          <TextField label="Release Name" source="release_name" />
          <TextField label="iOS Tag" source="ios_tag" />
          <TextField label="Android Tag" source="android_tag" />
          <TextField label="Created At" source="created_at" />
        </Datagrid>
      </List>
    </Box>
  );
};
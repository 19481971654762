import React, { useState } from "react";
import PublishIcon from '@mui/icons-material/Publish';
import { useDataProvider, useRefresh } from "react-admin";
import { sentryErrorHandler } from "../api/SentryErrorHandler";

export const AltDashboardUploadComponent = (props) => {
  const [fileReader] = useState(new FileReader());
  const dataProvider = useDataProvider();
  const clientKey = props.id;

  const refresh = useRefresh();
  var file;
  var success = true;
  var image_list = [];
  var url_list;

  function successAlert() {
    if(success){
      alert("alternate dashboard logo uploaded successfully")
      refresh()
    } else {
      alert("alternate dashboard logo failed to upload")
    }
  }

  const dataURItoBlob = async (dataURI) => {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    var length = binary?.length || 0;
    for(var i = 0; i < length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
  }

  const presignedURLs = async (filename) => {
    try {
      const {data: response, error} = await dataProvider.create('alt_dashboard_logos', {id: clientKey, filter:{filename}});
      if (error) {
        success = false;
        throw error;
      }
      return response.urls;
    } catch (error) {
      sentryErrorHandler(error, "PresignedURLs", "FETCH_FAILED");
      return Array(7).fill(null);
    }
  };

  const resizeImage = async (imgData, scaleFactor, filenames) => {
    if(!Array.isArray(filenames)){
      filenames = [filenames]
    }
    var img = new Image();
    img.src = await imgData;
    await img.decode()

    var elem = document.createElement('canvas');

    elem.width = img.width * scaleFactor;
    elem.height = img.height * scaleFactor;

    var ctx = elem.getContext('2d');
    ctx.drawImage(img, 0, 0, elem.width, elem.height);

    var srcEncoded = ctx.canvas.toDataURL('image/png', 1);
  
    for (const name of filenames) {
      const url = url_list.shift();
      image_list.push({ "data": await dataURItoBlob(srcEncoded), "url": url });
    }
  }

  const handleFileUpload = (event) => {
    file = event.target.files[0];
    fileReader.onloadend = handleFileRead;
    fileReader.readAsDataURL(file);
  }

  const handleFileRead = async (event) => {
    var data = fileReader.result;

    if(file.type !== 'image/png'){
      alert("Invalid file type! Please upload a png.");
      return;
    }

    if(!confirm(`Does this information look correct?\n\nfilename: ${file.name}`)){return;}

    const originalFilename = file.name.slice(0, -4)

    url_list = await presignedURLs(originalFilename);
    
    await resizeImage(data, 1.00, [`${originalFilename}@3x`, `${originalFilename}-xxhdpi`])
    await resizeImage(data, 0.66, [`${originalFilename}@2x`, `${originalFilename}-xhdpi`])
    await resizeImage(data, 0.50, `${originalFilename}-hdpi`)
    await resizeImage(data, 0.33, [`${originalFilename}`, `${originalFilename}-mdpi`])

    await uploadImages()
  }

  const uploadImages = async () => {
    try {
      await Promise.all(image_list.map(async (image) => {
        const {data, error} = await dataProvider.update('alt_dashboard_logos', {body: image["data"], filter:{ url: image["url"]}});
        if (error) {
          success = false;
          throw error;
        }
      }));
      successAlert();
    } catch (error) {
      success = false;
      sentryErrorHandler(error, 'uploadImages', 'FAILED_UPLOAD')
      successAlert();
    }
  }

  const buttonStyle = {
     display: "inline-block",
     margin: "0 0.3em 0.3em 0",
     borderRadius: "2em",
     boxSizing: "border-box",
     textDecoration: "none",
     fontFamily: 'Roboto',
     fontWeight: "300",
     color: "#FFFFFF",
     backgroundColor: "#4eb5f1",
     textAlign: "center",
     cursor: "pointer"
  }

  const uploadBtn = <button style={buttonStyle}><label htmlFor="inputfile" style={{cursor: "pointer"}}><PublishIcon /></label></button>

  return (
    <div id="FileHandler">
      <input type="file" name="file" className="inputfile" id="inputfile" onChange={(e)=> {handleFileUpload(e); e.target.value=null }} hidden/>
      <h4>Upload {uploadBtn}</h4>
    </div>
  );

}

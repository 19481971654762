import React, { useEffect, useState } from "react";
import { List, Datagrid, BooleanField, TextField, DateField, 
         Show, useShowController, usePermissions, Tab, 
         TabbedShowLayout, TopToolbar, useRecordContext,
         Button, FunctionField, useRedirect
       } from "react-admin";
import { renderIfPermissions } from "../api/permissionManager";
import { ResetPasswordButton } from "../components/PasswordReset";
import { TextField as Field, Box, Alert } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import { DeleteUserButton, ScanningUserDeleteDraftsField } from "./scanningUserDeleteDrafts";

const DriveAxleShowActions = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions()
  return (
    <TopToolbar>
      {record != null && (renderIfPermissions(permissions, 'customers', "write", <DeleteUserButton email={record.email} />))}
    </TopToolbar>
  );
};

export const DriveAxleShow = (props) => {
  const { permissions } = usePermissions();
  const [searchParams] = useSearchParams();
  const redirect = useRedirect();

  let record, error, isLoading;
  try {
    ({ record, error, isLoading } = useShowController());
  } catch (e) {
    redirect("/scanning_users");
    return <div>Redirecting...</div>;
  }
  // Axle considers an SSO user to be one with a non-null idp_code or oidc_subject_identifier
  const passwordUser = record?.idp_code == null && record?.oidc_subject_identifier == null;

  useEffect(() => {
    if (error) {
      redirect("/scanning_users");
    }
  }, [error, redirect]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error occurred: {error.message}</div>;

  return(
    <>
      {searchParams.get("delete_draft_created") === "" &&
        <div style={{ "paddingTop?": "10px" }}>
          <Alert severity="warning">
            Drive Axle user deletion will NOT take effect until approval by a second administrator
          </Alert>
        </div>
      }
      <Show actions={renderIfPermissions(permissions, 'customers', "write", <DriveAxleShowActions />)} {...props}>
          <TabbedShowLayout syncWithLocation={false} spacing={2}>
            <Tab label="User Info">
              <TextField source="name"/>
              <TextField source="email"/>
              <DateField source="last_sign_in_at" showTime/>
              <DateField source="created_at" showTime/>
              <BooleanField source="active"/>
              <TextField source="last_device_name"/>
              <TextField source="last_device_type"/>
              <TextField source="last_device_identifier"/>
              <TextField source="last_user_agent"/>
              <TextField source="last_drive_axle_version"/>
              <FunctionField id="su-login-method" label="Login Method" render={ () => (
                  passwordUser ? "Password" : "SSO"
                )} 
              />
              <TextField source="idp_code" />
              {renderIfPermissions(permissions, "customers", "write",
                passwordUser && <ResetPasswordButton record={record} {...props}/>
              )}
            </Tab>
            {renderIfPermissions(permissions, "customers", "write",
              <Tab label="Account Delete Drafts">
                <ScanningUserDeleteDraftsField email={record?.email}/>
              </Tab>
            )}
          </TabbedShowLayout>
      </Show>
    </>
  );
}

export const DriveAxleList = (props) => {
  const [email, setEmail] = useState('');
  const [input, setInput] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  const handleSearch = () => {
    !input.trim() ? setEmail('') : setEmail(input.toLowerCase())
  };
  const handleClear = () => {
    setEmail('');
    setInput('');
  };

  return (
    <div>
      <Box display="flex" gap={2} alignItems="center" marginBottom={2}>
        <Field
          label="User Email"
          variant="outlined"
          value={input}
          onChange={handleInputChange}
          size="small"
        />
        <Button
          label="Search"
          variant="contained"
          color="primary"
          onClick={handleSearch}
        />
        {email && (<Button
          label="Clear"
          variant="contained"
          color="inherit"
          onClick={handleClear}
        />)}
        
      </Box>
      {!email ? (
        <h2 align="center" style={{ margin: 35, padding: 10 }}>Please enter an email to begin.</h2>
      ) : (
        <List filter={{email: email}} exporter={false}>
          <Datagrid rowClick="show" bulkActionButtons={false} size="medium" empty={<h2 align='center' style={{ margin: 35, padding: 10 }}>No users found.</h2>}>
            <TextField source="name" />
            <TextField source="email" />
            <DateField source="last_sign_in_at" showTime />
            <DateField source="created_at" showTime />
          </Datagrid>
        </List>
      )}
    </div>
  );
};
import { ContentCopy } from "@mui/icons-material";
import { Alert, Box, Drawer, IconButton, Popper, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState, useRef } from "react";

const StyledCommand = styled(Typography)({
    backgroundColor: '#f5f5f5',
    border: '2px solid #ddd',
    borderRadius: '1em',
    marginLeft: '1em',
    padding: '2rem 1em 1em',
    fontFamily: 'monospace',
    color: '#333',
    wordBreak: 'break-word',
    position: 'relative',
});

const CopyButton = React.forwardRef((props, ref) => (
    <IconButton {...props} ref={ref} sx={{position: 'absolute', right: '1em', top: '0.5em', fontSize: '0.75em', }}>
        <ContentCopy />
    </IconButton>
));

export const CommandDrawer = ({ uuidExpand, handleCloseUUID, patchDetails }) => {
    const theme = useTheme();
    const [copySuccess, setCopySuccess] = useState(false);
    const copyButtonRef = useRef(null);
    const handleCopyCommand = (event, command) => {
        event.stopPropagation();
        navigator.clipboard.writeText(command)
            .then(() => {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000);
            })
            .catch(() => {
                setCopySuccess(false);
            });
    };
    
    return (
        <Drawer
            anchor="right"
            open={uuidExpand}
            onClose={(e) => handleCloseUUID(e)}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '50%',
                    padding: '1em',
                    backgroundColor: theme.palette.background.default,
                },
            }}
        >
            <Box key={patchDetails.uuid} sx={{ position: "relative", padding: '1em' }}>
                <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Escalate this command to engineering to enable this route section's patch.
                </Typography>
                <Typography variant="subtitle1" sx={{ marginLeft: '1em', marginTop: '3em' }}>
                    Command for UUID: {patchDetails.uuid}:
                </Typography>
                <StyledCommand variant="body2">
                    {patchDetails.command}
                    <CopyButton ref={copyButtonRef} onClick={(event) => handleCopyCommand(event, patchDetails.command)} />
                </StyledCommand>
                <Popper
                    open={copySuccess}
                    anchorEl={copyButtonRef.current}
                    placement="top"
                    sx={{ zIndex: 1300 }}
                >
                    <Alert variant="filled" severity="success">Command copied to clipboard!</Alert>
                </Popper>
            </Box>
        </Drawer>
    );
};

export const NoticeDrawer = ({ handleCloseNotices, noticeExpanded, notices }) => {
    const theme = useTheme();
    return (
        <Drawer
            anchor="right"
            open={noticeExpanded}
            onClose={handleCloseNotices}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '50%',
                    padding: '1em',
                    backgroundColor: theme.palette.background.default,
                },
            }}
        >
            <Box style={{ display: 'block', backgroundColor: '#fbcd90', padding: '1em', borderRadius: '8px', color: '#333942' }}>
                <h2 style={{ textAlign: 'center' }}>Notices:</h2>
                <hr style={{ border: "1px solid black", width: "100%" }} />
                {notices.map((notice, index) => (
                    <div key={index} style={{ marginBottom: '1em' }}>
                        <div style={{ marginBottom: '0.5em' }}>
                            <Typography style={{ fontWeight: 'bold' }}>TITLE</Typography>
                            <Typography style={{ marginLeft: '20px' }}>{notice.title}</Typography>
                        </div>
                        <div style={{ marginBottom: '0.5em' }}>
                            <Typography style={{ fontWeight: 'bold' }}>CODE</Typography>
                            <Typography style={{ marginLeft: '20px' }}>{notice.code}</Typography>
                        </div>
                        <div style={{ marginBottom: '0.5em' }}>
                            <Typography style={{ fontWeight: 'bold' }}>SEVERITY</Typography>
                            <Typography style={{ marginLeft: '20px' }}>{notice.severity}</Typography>
                        </div>
                    </div>
                ))}
            </Box>
        </Drawer>
    );
}
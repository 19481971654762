import React, { useState } from "react";
import PublishIcon from '@mui/icons-material/Publish';
import { useDataProvider, useRefresh } from "react-admin";
import { sentryErrorHandler } from "../api/SentryErrorHandler";

export const AppAssetUploadComponent = (props) => {
  const [fileReader] = useState(new FileReader());
  const dataProvider = useDataProvider();
  const clientKey = props.id;
  const refresh = useRefresh();
  var appAssetName = props.appAssetName;
  var file;

  const presignedURL = async () => {
    return dataProvider.update('app_assets', {id: clientKey, appAssetName, getPresigned: true })
    .then(({data}) => {
      return data.url
    }).catch(error => {
      sentryErrorHandler(error, 'presignedURL', 'UPDATE')
      throw error;
    })
  }

  const handleFileUpload = (event) => {
    file = event.target.files[0];
    fileReader.onloadend = handleFileRead;
    fileReader.readAsArrayBuffer(file);
  }

  const checkIconReqs = (width, height) => {
    if (height !== width) {
      alert(`Invalid dimensions! App Icon dimensions is not square. Uploaded image is ${width}x${height}`);
      return false;
    } else if (width > 1800) {
      alert(`Invalid dimensions! App Icon dimensions are too large. Uploaded image is ${width}x${height}. Resize image.`);
      return false;
    }
    return true;
  }

  const checkAlphaChannel = (img) => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 3; i < data.length; i += 4) {
      if (data[i] !== 255) {
        return true;
      }
    }
    return false;
  };

  const checkLogoReqs = (width, height, img) => {
    if (height >= width) {
      alert(`Invalid dimensions! Logo dimensions is not rectangular. Uploaded image is ${width}x${height}`);
      return false;
    } else if (width > 1800) {
      alert(`Invalid dimensions! Logo dimensions are too large. Uploaded image is ${width}x${height}. Resize image.`);
      return false;
    } else if (!checkAlphaChannel(img)) {
      alert('Invalid image! Logo should have an alpha channel.');
      return false;
    }
    return true;
  };

  const handleFileRead = async (event) => {
    var fileReadData = fileReader.result;
  
    if (file.type !== 'image/png') {
      alert("Invalid file type! Please upload a png.");
      return;
    }
  
    // Create an Image element to get the dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);
  
    const handleDimensions = async () => {
      return new Promise((resolve, reject) => {
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          if (appAssetName === "app-icon") {
            if (!checkIconReqs(width, height)) {
              reject();
            }
          } else if (appAssetName === "dashboard-logo" || appAssetName === "login-logo") {
            if (!checkLogoReqs(width, height, img)) {
              reject();
            }
          }
  
          resolve(); // Resolve the promise when dimensions are valid
        };
  
        img.onerror = () => {
          alert("Error loading the image.");
          reject(); // Reject the promise in case of an error
        };
      });
    };
  
    try {
      await handleDimensions(); // Wait for the dimensions to be checked
      const url = await presignedURL(); // Wait for the presigned URL
      const {data: response, error} = await dataProvider.update('app_assets', {id: url, body: fileReadData, getPresigned: false})
      if(error){
        alert(`App asset failed to upload. Check Sentry.`);
        throw error
      }
      alert(`App asset uploaded successfully`);
      refresh();
    } catch (error) {
      sentryErrorHandler(error, 'handleFileRead')
    }
  };

  const buttonStyle = {
    display: "inline-block",
    margin: "0 0.3em 0.3em 0",
    borderRadius: "2em",
    boxSizing: "border-box",
    textDecoration: "none",
    fontFamily: 'Roboto',
    fontWeight: "300",
    color: "#FFFFFF",
    backgroundColor: "#4eb5f1",
    textAlign: "center",
    cursor: "pointer"
  }

  const uploadBtn = <button style={buttonStyle}><label htmlFor="inputfile" style={{ cursor: "pointer" }}><PublishIcon /></label></button>

  return (
    <div id="FileHandler">
      <input type="file" name="file" className="inputfile" id="inputfile" onChange={(e) => { handleFileUpload(e); e.target.value = null }} hidden />
      <h4>Upload {uploadBtn}</h4>
    </div>
  );

}

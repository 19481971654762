import React, { useState } from "react";
import { useDataProvider, useNotify, useRedirect } from "react-admin";
import { CircularProgress, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { ENVIRONMENT } from '../config';

export const BadRouteFinderForm = (props) => {
    const dataProvider = useDataProvider();
    let [username, setUsername] = useState(localStorage.getItem("PRD_username"));
    let [clientKey, setClientKey] = useState(localStorage.getItem("PRD_client_key"));
    let [loadRef, setLoadRef] = useState(localStorage.getItem("PRD_load_ref"));
    let [clientLoads, setClientLoads] = useState([]);
    let [loading, setLoading] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();

    let submitHandler = async (event) => {
        event.preventDefault();
        let valid = true
        setLoading(true);

        if (clientKey == null || clientKey.length == 0) {
            alert("Client Key required")
            valid = false
        }
        else if (username == null || username.length == 0) {
            alert("Username required")
            valid = false
        }
        else if (loadRef == null || loadRef.length == 0) {
            alert("Load Reference required")
            valid = false
        }
        if (valid) {
            const {data: response} = await dataProvider.getList('routes', { id: clientKey, filter: { username, loadRef } });
            if(!response || response.length === 0){
                alert(`Request failed. No routes found. Client key does not exist`);
                setLoading(false);
            }
            setClientLoads(response)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    let usernameChange = (event) => {
        setUsername(event.target.value);
        localStorage.setItem("PRD_username", event.target.value)
    }
    let clientKeyChange = (event) => {
        setClientKey(event.target.value);
        localStorage.setItem("PRD_client_key", event.target.value)
    }
    let loadRefChange = (event) => {
        setLoadRef(event.target.value);
        localStorage.setItem("PRD_load_ref", event.target.value)
    }

    //default chunk size
    const CHUNK_SIZE = 100

    let submitHandlerBadChunk = async (event, id) => {
        event.preventDefault();
        //Adding ability to copy id. Prior to this, we had to jot it down because you can't copy the text inside the button.
        //This will be convenient and save the user time.
        navigator.clipboard.writeText(id).then(() => {
            notify(`Copied: ${id}`, {type: 'success'});
        }).catch(err => {
            notify(`Failed to copy text: ${err}`, {type: 'error'});
        });
        setLoading(true);

        const {data: response} = await dataProvider.getOne('routes', { id, filter:{ type: 'bad_chunk', chunkSize: CHUNK_SIZE } });
        if(!response || response.length === 0){
            alert(`Request failed. Route not found.`);
            setLoading(false);
        }
        if (response) {
            props.setRouteFromClient(response.geometry);
            props.setBadChunks(response.bad_geometry_chunks);
            props.setRouteId(id);
            setLoading(false);
            redirect("/pluggable_routes/differences_map");
        }
    }

    const RenderLoads = () => {
        return (
            <TableContainer component={Paper} style={{ padding: "1%", marginTop: "3%" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Route ID</TableCell>
                            <TableCell>Routing Engine</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Stop Number</TableCell>
                            <TableCell>HERE Import Status</TableCell>
                            <TableCell>HERE Request URL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientLoads.map((load, index) => {
                            if (load.routing_engine != 'null') {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <div>
                                                <form onSubmit={(event) => submitHandlerBadChunk(event, load.id)}>
                                                    <Button type='submit' variant="contained" color="primary">
                                                        {load.id}
                                                    </Button>
                                                </form>
                                            </div>
                                        </TableCell>
                                        <TableCell>{load.routing_engine}</TableCell>
                                        <TableCell>{load.created_at}</TableCell>
                                        <TableCell>{load.stop_number}</TableCell>
                                        <TableCell>{load.import_status}</TableCell>
                                        <TableCell>{load.here_request_url}</TableCell>
                                    </TableRow>
                                );
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const RenderResults = (clientLoads) => {
        //create a default sort order for route info list
        var sortedLoads = clientLoads.sort((a, b) => {
            if (a.created_at > b.created_at) {
                return -1;
            }
        });
        if (loading) {
            return (<div style={{ marginTop: "1rem" }}><CircularProgress />Loading route onto Differences Map</div>);
        }
        else if ((JSON.stringify(clientLoads)).length > 10) {
            if (clientLoads != null) {
                return RenderLoads();
            }
        }
    }

    const inputStyle = {
        margin: "8px",
    }

    return (
        <div style={{ marginRight: "3%" }}>
            <h2>Bad Route Finder</h2>
            <p>Find sections along routes which are causing errors</p>

            <p>Submit client key, username, and load reference: </p>
            <ul>
                <li>Click the {ENVIRONMENT === 'staging' ? <span style={{ color: "#2196f3" }}>BLUE</span> : <span style={{ color: "#f50057" }}>RED</span>} route ID button to find sections of that route with errors {"("}performs the same function as the Route Difference Generator{")"}</li>
                <li>Once loading is completed you can view the route and any errors in the Differences Map tab</li>
            </ul>
            <form onSubmit={submitHandler}>
                <div style={inputStyle}>
                    <TextField
                        variant="outlined"
                        label="Client Key"
                        value={clientKey}
                        size="small"
                        onChange={clientKeyChange} >
                    </TextField>
                </div>
                <div style={inputStyle}>
                    <TextField
                        variant="outlined"
                        label="Username"
                        value={username}
                        size="small"
                        onChange={usernameChange} />
                </div>
                <div style={inputStyle}>
                    <TextField
                        variant="outlined"
                        label="Load Reference"
                        value={loadRef}
                        size="small"
                        onChange={loadRefChange} />
                </div>
                <div style={inputStyle}>
                    <Button type='submit' variant="contained" color="primary">Send Request</Button>
                </div>
            </form>
            {RenderResults(clientLoads, loading)}
        </div>
    )
}
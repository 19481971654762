import { useState } from "react";
import { Button, Confirm, useNotify, useRefresh, useRecordContext, useDataProvider } from "react-admin";
import { styled } from '@mui/system';

const CreateButton = styled(Button)(({ theme }) => ({
    background: '#8AC2EC',
        color: 'black',  
        marginLeft: 'auto',
        display: 'block',
        width: '100px', 
  }));

const KeySwitchCreateButton = () => {
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false)
    const refresh = useRefresh();
    const notify = useNotify();

    const record = useRecordContext();

    const app_client_key = record.client_key

    const handleCreateKeySwitch = async (destinationKey) => {
        await dataProvider.create('apps', {switcher: true, app_client_key, destinationKey})
        .then(({ data }) => {
            setOpen(false);
            refresh();
            notify(`Token has been added for ${destinationKey}`, {type: "info",});
        }).catch(error => {
            setOpen(false);
            refresh();
            notify(`${destinationKey} not in client group '${app_client_key}'.`, {type: "warning"});
        })              
    };

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        const destinationKey = prompt('Client Key?')
        handleCreateKeySwitch(destinationKey);
        setOpen(false);
    };

    return (
        <>
            <CreateButton id="CreateKeySwitch" label="Create" onClick={handleClick} />
            <Confirm
                isOpen={open}
                title="Create Key Switcher"
                content="Do you want to create a key switcher?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};



export default KeySwitchCreateButton;
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Create, Edit, SimpleForm, TextInput, DateInput, required, NumberInput, AutocompleteInput, 
  useRedirect, Toolbar, SaveButton, regex, minValue, Button,
  useDataProvider
} from "react-admin";
import { useFormContext } from "react-hook-form"
import { Box, Card, CardContent, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import CurrencyInput from "../components/CurrencyInput";
import { PerLoadBillingCreateDoc, PerLoadBillingEditDoc } from "../documentation/PerLoadBillingDoc";
import { styled } from "@mui/system";
import { debounce } from "lodash";
import { useLocation, useParams } from "react-router-dom";
import { CustomAccountContractCreate } from "./billing_accounts";

const CancelButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-outlinedSecondary': {
    border: '1px solid gray',
    color: 'gray',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
    margin: '2em',
  },
}));

const base_transform = data => {
  let values = {
    contract_structure_code: data.contract_structure_code,
    price_sheet_code: data.price_sheet_code,
    product_prefix: data.product_prefix,
    name_prefix: data.name_prefix,
    contract_anniversary: new Date(data.contract_anniversary).toISOString(),
    valid_from: new Date(data.valid_from).toISOString(),
    app_maintenance: {
      fee: to_cents(data.app_maintenance_fee),
      period: data.app_maintenance_period
    },
    companion: {
      monthly_usage_fee: to_cents(data.companion_monthly_usage_fee),
      per_load_usage_fee: to_cents(data.companion_per_load_usage_fee),
      per_load_usage_fee_minimum: to_cents(data.companion_per_load_usage_fee_minimum)
    },
    in_cab: {
      monthly_usage_fee: to_cents(data.in_cab_monthly_usage_fee),
      per_load_usage_fee: to_cents(data.in_cab_per_load_usage_fee),
      per_load_usage_fee_minimum: to_cents(data.in_cab_per_load_usage_fee_minimum)
    }
  }

  if (data.telematics_integration_monthly_usage_fee) {
    Object.assign(values, { telematics_integration: { monthly_usage_fee: to_cents(data.telematics_integration_monthly_usage_fee) } })
  }
  if (data.minimum_monthly_payment) {
    Object.assign(values, { minimum_monthly_payment: to_cents(data.minimum_monthly_payment) })
  }
  if (data.max_fee_multiplier) values.max_fee_multiplier = data.max_fee_multiplier

  return values
}

const PerLoadBillingEditToolbar = ({ id }) => {
  const redirect = useRedirect();
  const handleCancel = () => {
    redirect(`/billing_accounts/${id}/show`);
  };
  return (
    <Toolbar>
      <SaveButton label="Save" />
      <CancelButton
        variant="outlined"
        onClick={handleCancel}
        color="secondary"
        label="Cancel"
      />
    </Toolbar>
  )
}

export const PerLoadBillingCreate = () => {
  const redirect = useRedirect();

  const transform = data => {
    let values = base_transform(data)
    Object.assign(values, { billing_account_name: data.billing_account_name })
    return values
  }

  const onSuccess = (data) => {
    var message = 'The following items were created:\n\n'
    for (const field in data) {
      if (field != 'id' && field != 'deactivated_account_contract_ids') {
        message += (field + ": " + data[field].toString() + "\n\n")
      }
    }
    alert(message)
    redirect("show", "billing_accounts", data.id);
  };

  return (
    <Create mutationOptions={{ onSuccess }} transform={transform} aside={<PerLoadBillingCreateDoc />}>
      <SimpleForm toolbar={<PerLoadBillingEditToolbar id={data.id} />}>
        <TextInput fullWidth source="billing_account_name" label="Billing Account Name" validate={required()} />
        <BaseFormContents />
      </SimpleForm>
    </Create>
  )
}

export const PerLoadBillingEdit = () => {
  const redirect = useRedirect();
  const { id } = useParams();
  const location = useLocation();
  const { name } = location.state || {};
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
  }

  const transform = data => {
    let values = base_transform(data)
    Object.assign(values, { billing_account_external_id: data.id })
    return values
  }

  const onSuccess = (data) => {
    var message = 'The following items were created/updated:\n\n'
    for (const field in data) {
      if (field != 'id') {
        message += (field + ": " + data[field].toString() + "\n\n")
      }
    }
    alert(message)
    redirect("show", "billing_accounts", data.id);
  };

  return (
    <Card>
      <CardContent>
          <Tabs
              value={activeTab}
              onChange={handleTabChange}
              selectionFollowsFocus
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              sx={{
                borderBottom: '2px solid', 
                borderColor: 'divider',
              }}
          >
          <Tab sx={{
                  bgcolor: activeTab === 0 ? 'secondary.main' : 'transparent',
                  color: activeTab === 0 ? 'white' : 'text.primary',
                  '&:hover': { bgcolor: activeTab === 0 ? 'secondary.main' : 'action.hover' },
                  borderBottom: activeTab === 0 ? '6px solid' : 'none',
                  borderColor: activeTab === 0 ? 'primary.main' : 'transparent'
                }}
                label="Per-Load Structure" />
          <Tab sx={{
                    bgcolor: activeTab === 1 ? 'secondary.main' : 'transparent',
                    color: activeTab === 1 ? 'white' : 'text.primary',
                    '&:hover': { bgcolor: activeTab === 1 ? 'secondary.main' : 'action.hover' },
                    borderBottom: activeTab === 1 ? '6px solid' : 'none',
                    borderColor: activeTab === 1 ? 'primary.main' : 'transparent'
                }}
                label="Custom Structure" />
          </Tabs>
          <Box mt={3}>
              {activeTab === 0 && (
                <Edit resource="per_load_billing_structure" transform={transform} mutationMode="pessimistic" mutationOptions={{ onSuccess }} aside={<PerLoadBillingEditDoc />}>
                  <SimpleForm toolbar={<PerLoadBillingEditToolbar id={id} />} >
                    <TextInput fullWidth source="id" label="Billing Account External ID" validate={required()} readOnly />
                    <BaseFormContents />
                  </SimpleForm>
                </Edit>
              )}
              {activeTab === 1 && <CustomAccountContractCreate id={id} name={name} />}
          </Box>
      </CardContent>
  </Card>
  )
}

const Separator = () => <Box pt="1em" />;

const BetterNumberInput = (props) => <NumberInput fullWidth onWheel={(e) => e.target.blur()} {...props} />

const to_cents = (dollars) => parseInt(dollars * 100)

const BaseFormContents = () => {
  const [namePrefix, setNamePrefix] = useState('');
  const [contractStructureCode, setContractStructureCode] = useState('');
  const [priceSheetCode, setPriceSheetCode] = useState('');
  const [productPrefix, setProductPrefix] = useState('');
  const [contractStructureError, setContractStructureError] = useState('');
  const [priceSheetError, setPriceSheetError] = useState('');
  const [productPrefixError, setProductPrefixError] = useState('');
  const [baseError, setBaseError] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const dataProvider = useDataProvider();
  const { setValue } = useFormContext();

  const validatePrefixes = useCallback(
    debounce(async () => {
      const tailError = 'Try a different Invoice Line Item Prefix.'
      try {
        // Contract Structure Validation
        let response = await dataProvider.getOne('contract_structures', { id: contractStructureCode });
        setContractStructureError(response.data.id ? `Contract structure code must be unique. ${tailError}` : '');
        // Price Sheet Validation
        response = await dataProvider.getOne('price_sheets', { id: priceSheetCode });
        setPriceSheetError(response.data.id.length > 0 ? `Price sheet code must be unique. ${tailError}` : '');
        // Product Prefix Validation
        const productSuffixes = [
          '-COMPANION-PER-LOAD-MINIMUM',
          '-COMPANION-PER-LOAD',
          '-COMPANION-PER-LOAD-MAX',
          '-IN-CAB-MONTHLY-USAGE',
          '-IN-CAB-PER-LOAD',
          '-IN-CAB-PER-LOAD-MAX',
        ];
        const matchList = await Promise.all(
          productSuffixes.map(async (suffix) => {
            const code = `${productPrefix}${suffix}`;
            const response = await dataProvider.getOne('products', { id: code });
            return response.data.id !== 'empty' ? code : null;
          })
        );
        const matches = matchList.filter(Boolean);
        setProductPrefixError(matches.length ? `Duplicate codes: ${matches.join(', ')}. ${tailError}` : '');
      } catch (e) {
        setBaseError(`Prefix validation failed: ${e}`)
      }
      setIsValidating(false);
    }, 300),
    [productPrefix, contractStructureCode, priceSheetCode, dataProvider]
  );
 
  useEffect(() => {
    if (!isValidating && contractStructureCode && priceSheetCode && productPrefix){
      setIsValidating(true);
      validatePrefixes();
    }
  }, [contractStructureCode, priceSheetCode, productPrefix]);

  const handleNamePrefixChange = (event) => {
    const value = event.target.value;
    const date = new Date();
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    const codeSuffix = `${month}-${day}-${year}`
    setBaseError('');
    setNamePrefix(value);
    if (value) {
      const formattedCode = `${value.toUpperCase()
        .replace(/\s+/g, '-')
        .replace(/[^A-Z0-9-]/g, '')}-${codeSuffix}`;
      setContractStructureCode(formattedCode);
      setPriceSheetCode(formattedCode);
      setProductPrefix(formattedCode);
      setValue('contract_structure_code', formattedCode);
      setValue('price_sheet_code', formattedCode);
      setValue('product_prefix', formattedCode);
    } else {
      setContractStructureCode('');
      setContractStructureError('')
      setPriceSheetCode('');
      setPriceSheetError('');
      setProductPrefix('');
      setProductPrefixError('');
      setValue('contract_structure_code', '');
      setValue('price_sheet_code', '');
      setValue('product_prefix', '');
    }
  };

  const first_of_the_month = (value, allValues) => { const date = new Date(value); return date.getUTCDate() != 1 ? 'Must enter first of the month' : undefined}
  const uppercase_alphanumeric = () => regex(/^[A-Z0-9-]*$/, "Can only contain uppercase letters, numbers, and '-' characters")
  const no_dash_on_end = () => regex(/^(?!.*-$).+$/, "Cannot contain a '-' on the end")
  const positive_number = () => minValue(0, "Input must be positive")
  const code_format = [required(), uppercase_alphanumeric()]
  const product_prefix_format = [required(), uppercase_alphanumeric(), no_dash_on_end()]
  const currency_format = [required(), positive_number()]

  return (
    <>
      <TextInput fullWidth source="name_prefix" label="Invoice Line Item Prefix" validate={required()} onChange={handleNamePrefixChange} defaultValue={namePrefix} />
      {isValidating && <Typography style={{display:'flex', fontSize: 'x-small', marginBottom: '0.5em', marginTop:'-1.5rem'}} variant="body2">Validating codes and prefix... <CircularProgress size={10} /></Typography>}
      {baseError && <div style={{color: 'red', fontSize: 'x-small', marginBottom: '0.5em', marginTop:'-1.5rem'}}>{baseError}</div>}

      <TextInput fullWidth source="contract_structure_code" label="Contract Structure Code" validate={code_format} value={contractStructureCode} readOnly />
      {contractStructureError && <div style={{color: 'red', fontSize: 'x-small', marginBottom: '0.5em', marginTop:'-1.5rem'}}>{contractStructureError}</div>}
      
      <TextInput fullWidth source="price_sheet_code" label="Price Sheet Code" validate={code_format} value={priceSheetCode} readOnly />
      {priceSheetError && <div style={{color: 'red', fontSize: 'x-small', marginBottom: '0.5em', marginTop:'-1.5rem'}}>{priceSheetError}</div>}
      
      <TextInput fullWidth source="product_prefix" label="Product Prefix" validate={product_prefix_format} value={productPrefix} readOnly />
      {productPrefixError && <div style={{color: 'red', fontSize: 'x-small', marginBottom: '0.5em', marginTop:'-1.5rem'}}>{productPrefixError}</div>}

      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={1} mr={{ xs: 0, sm: '2em' }}>
          <DateInput source="valid_from" label="Valid From" validate={[required(), first_of_the_month]} />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '2em' }}>
          <DateInput source="contract_anniversary" label="Contract Anniversary" validate={required()} />
        </Box>
      </Box>

      <BetterNumberInput source="max_fee_multiplier" label="Max Fee Multiplier" defaultValue={1.5} />
      <Separator />

      <Typography variant="h6" gutterBottom> App Maintenance </Typography>
      <CurrencyInput fullWidth source="app_maintenance_fee" label="Fee" validate={currency_format} />
      <AutocompleteInput fullWidth source="app_maintenance_period" label="Period" defaultValue={'monthly'} choices={[{ id: 'monthly', name: 'Monthly' }, { id: 'annual', name: 'Annual' }]} validate={required()} />
      <Separator />

      <Typography variant="h6" gutterBottom> Companion </Typography>
      <CurrencyInput fullWidth source="companion_monthly_usage_fee" label="Monthly Usage Fee" validate={currency_format} />
      <CurrencyInput fullWidth source="companion_per_load_usage_fee" label="Per-Load Usage Fee" validate={currency_format} />
      <CurrencyInput fullWidth source="companion_per_load_usage_fee_minimum" label="Per-Load Usage Fee Minimum" validate={currency_format} />
      <Separator />

      <Typography variant="h6" gutterBottom> In-Cab </Typography>
      <CurrencyInput fullWidth source="in_cab_monthly_usage_fee" label="Monthly Usage Fee" validate={currency_format} />
      <CurrencyInput fullWidth source="in_cab_per_load_usage_fee" label="Per-Load Usage Fee" validate={currency_format} />
      <CurrencyInput fullWidth source="in_cab_per_load_usage_fee_minimum" label="Per-Load Usage Fee Minimum" validate={currency_format} />
      <Separator />

      <Typography variant="h6" gutterBottom> Telematics Integration </Typography>
      <CurrencyInput fullWidth source="telematics_integration_monthly_usage_fee" label="Monthly Usage Fee (optional)" validate={positive_number()} />
      <Separator />

      <Typography variant="h6" gutterBottom> Minimum Monthly Payment </Typography>
      <CurrencyInput fullWidth source="minimum_monthly_payment" label="Minimum Monthly Payment (optional)" validate={positive_number()} />
    </>
  )
}
import {
    TextField as Field, Box, Paper, Typography, useTheme,
    Table, TableHead, TableRow, TableCell, TableBody, TableContainer,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Tooltip,
    TextField
} from "@mui/material";
import { Container, styled } from "@mui/system";
import { useState, useEffect } from "react";
import { Button, Title, useDataProvider, useNotify, Link, usePermissions } from "react-admin";
import { Delete, Info, Visibility } from "@mui/icons-material";
import { IALoading } from "../components/IAProgress";
import { renderIfPermissions } from "../api/permissionManager";

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex'
}));
const StyledEmailField = styled(Field)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        backgroundColor: theme.palette.background.paper,
        '& fieldset': { borderColor: theme.palette.divider },
        '&:hover fieldset': { borderColor: theme.palette.text.secondary },
    },
    '& .MuiInputLabel-root': { color: theme.palette.text.secondary },
}));
const ClearButton = styled(Button)(({ theme }) => ({
    borderRadius: '8px',
    textTransform: 'none',
    borderColor: theme.palette.divider,
    color: theme.palette.text.primary,
    '&:hover': {
        borderColor: theme.palette.text.secondary,
        backgroundColor: theme.palette.action.hover
    },
}));
const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold'
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// Reusable Delete Button Component
const DeleteActionButton = ({ label, confirmTitle, confirmText, onDelete, variant = "contained", isGroupUnsubscribe = false, email, groupName, type }) => {
    const [open, setOpen] = useState(false);
    const [confirmationInput, setConfirmationInput] = useState("");
    const [error, setError] = useState("");

    // Check if the input matches the email
    const isConfirmed = confirmationInput.trim().toLowerCase() === email.trim().toLowerCase();

    const handleConfirmClick = () => {
        if (isConfirmed) {
            onDelete();
            setOpen(false);
            setConfirmationInput("");
            setError("");
        } else setError("Email does not match. Please type the exact recipient email.");
    };

    const handleDialogClose = () => {
        setOpen(false);
        setConfirmationInput("");
        setError("");
    };

    const finalConfirmText = (
        <>
            <DialogContentText sx={{ fontWeight: "bold", color: "error.main" }}>
                You are about to delete a status for {email}
            </DialogContentText>
            <DialogContentText sx={{ mt: 2 }}>
                {isGroupUnsubscribe
                    ? `This will remove the "${groupName}" unsubscribe status for ${email}.`
                    : confirmText}
            </DialogContentText>
            <DialogContentText sx={{ mt: 2, fontWeight: "bold" }}>
                You MUST verify that {email} has explicitly requested this action.
            </DialogContentText>
            <DialogContentText sx={{ mt: 1 }}>
                Please confirm you have directly contacted {email} (the recipient) and received their approval for this deletion.
                Performing this action without their consent may violate security policies.
            </DialogContentText>
        </>
    );

    return (
        <>
            <Tooltip title={`Delete this ${isGroupUnsubscribe ? "group unsubscribe" : type} for ${email}`} arrow>
                <span>
                    <Button startIcon={<Delete />} label={label} variant={variant} color="error" size="small" onClick={() => setOpen(true)} sx={{ textTransform: "none" }} />
                </span>
            </Tooltip>
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>{confirmTitle}</DialogTitle>
                <DialogContent>
                    {finalConfirmText}
                    <TextField
                        label={`Type "${email}" to confirm`}
                        variant="outlined"
                        value={confirmationInput}
                        fullWidth margin="normal" error={!!error} helperText={error || "Enter the exact email address to enable confirmation."}
                        onChange={(e) => { setConfirmationInput(e.target.value); setError(""); }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleDialogClose} label="Cancel" />
                    <Button variant="contained" onClick={handleConfirmClick} label="Confirm - I have verified with recipient" color="error" disabled={!isConfirmed} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export const SendGridList = () => {
    const [email, setEmail] = useState('');
    const [input, setInput] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [backendUserId, setBackendUserId] = useState(null);
    const [scanningUserId, setScanningUserId] = useState(null);
    const theme = useTheme();
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const notify = useNotify();

    useEffect(() => {
        const cachedData = localStorage.getItem('sendGridSearchCache');
        if (cachedData) {
            const { email, statuses, backendUserId, scanningUserId } = JSON.parse(cachedData);
            setEmail(email || '');
            setInput(email || '');
            setStatuses(statuses || []);
            setBackendUserId(backendUserId || null);
            setScanningUserId(scanningUserId || null);
        }
    }, []);

    useEffect(() => {
        const cacheData = { email, statuses, backendUserId, scanningUserId };
        localStorage.setItem('sendGridSearchCache', JSON.stringify(cacheData));
    }, [email, statuses, backendUserId, scanningUserId]);

    const permissionCheck = (resource, requiredPermission) => {
        if (localStorage.getItem('granular_permissions')) {
            const resourcePerms = (permissions instanceof Map) ? permissions.get(resource) : permissions[resource];
            if (resourcePerms === undefined || resourcePerms === null) return false;
            return resourcePerms[requiredPermission];
        }
    };

    const handleSearch = async () => {
        if (!input.trim()) {
            setEmail('');
            setStatuses([]);
            setBackendUserId(null);
            setScanningUserId(null);
            setError(null);
            return;
        }
        setEmail(input);
        setLoading(true);
        setError(null);
        try {
            let isBackendUser = false;
            if (permissionCheck('backend_users', 'read_only')) {
                const backendResponse = await dataProvider.getOne('backend_users', { id: input });
                isBackendUser = backendResponse.data.email;
                setBackendUserId(isBackendUser ? backendResponse.data.id : null);
            } else setBackendUserId(null);

            let isScanningUser = false;
            if (permissionCheck('customers', 'read_only')) {
                const scanningResponse = await dataProvider.getOne('scanning_users', { id: input });
                isScanningUser = scanningResponse.data.email;
                setScanningUserId(isScanningUser ? scanningResponse.data.id : null);
            } else setScanningUserId(null);

            const response = await dataProvider.getOne('sendgrid', { id: input, meta: { isFreeLoaderSearch: true } });
            setStatuses(response.data.statuses || []);
        } catch (err) {
            setError('Failed to fetch data. Please double check the recipient email and try again.');
            setStatuses([]);
            setBackendUserId(null);
            setScanningUserId(null);
        } finally {
            setLoading(false);
        }
    };

    const handleClear = () => {
        setEmail('');
        setInput('');
        setStatuses([]);
        setError(null);
        setBackendUserId(null);
        setScanningUserId(null);
        localStorage.removeItem('sendGridSearchCache');
    };

    const handleDelete = async (type, groupId = null) => {
        try {
          const params = groupId ? { id: email, group_id: groupId, meta: type } : { id: email, isFreeLoaderSearch: true, meta: type };
          await dataProvider.delete('sendgrid', params);
          // Refetch the latest statuses from the server
          const response = await dataProvider.getOne('sendgrid', { id: email, meta: { isFreeLoaderSearch: true } });
          setStatuses(response.data.statuses || []);
          notify(`${type} removed successfully for ${email}`, { type: "success" });
        } catch (error) {
          notify(`Failed to remove ${type}: ${error}`, { type: "error" });
        }
      };

    const renderStatuses = () => {
        if (loading) return <div style={{ marginTop: '4em' }}><IALoading size={80} /></div>;
        if (error) return <Typography variant="body1" color="error" sx={{ mt: 4 }}>{error}</Typography>;
        if (statuses.length === 0 && email) return <Typography variant="body1" sx={{ mt: 4 }}>No active statuses found for {email}.</Typography>;

        const filteredStatuses = statuses.filter(status => !(status.type === 'group unsubscribe' && status.suppressed === false));

        const renderName = (type) => {
            switch (type) {
                case 'unsubscribe': return 'Global Unsubscribe';
                case 'group unsubscribe': return 'Group Unsubscribe';
                case 'bounce': return 'Bounce';
                case 'block': return 'Block';
                default: return type;
            }
        };

        const getTooltipText = (type) => {
            switch (type) {
                case 'group unsubscribe': return 'User unsubscribed from a specific type of email from us.';
                case 'unsubscribe': return 'User unsubscribed from all emails from us.';
                case 'block': return 'Email rejected due to message issues.';
                case 'bounce': return 'Email undeliverable and returned.';
                default: return '';
            }
        };

        const getDeleteProps = (status) => {
            switch (status.type) {
                case 'block': return { label: "Delete", confirmTitle: "Clear All Blocks?", confirmText: "This will remove ALL blocks", onDelete: () => handleDelete('Blocks'), email: status.email, type: status.type };
                case 'bounce': return { label: "Delete", confirmTitle: "Clear All Bounces?", confirmText: "This will remove ALL bounces", onDelete: () => handleDelete('Bounce'), email: status.email, type: status.type };
                case 'unsubscribe': return { label: "Delete", confirmTitle: "Clear Global Unsubscribe?", confirmText: "This will remove the global unsubscribe", onDelete: () => handleDelete('Unsubscribe'), email: status.email, type: status.type };
                case 'group unsubscribe': return { label: "Delete", confirmTitle: "Remove Group Unsubscribe?", onDelete: () => handleDelete('Group Unsubscribe', status.suppression_group_id), isGroupUnsubscribe: true, email: status.email, groupName: status.name || 'this group', type: status.type };
                default: return null;
            }
        };

        const getReason = (status) => {
            return status.type === 'group unsubscribe' ? `The email account is unsubscribed from ${status.name}` : '-';
        };

        return (
            <TableContainer component={Paper} sx={{ maxWidth: 'lg', mt: 4 }}>
                <Table sx={{ minWidth: 800 }}>
                    <TableHead>
                        <TableRow>
                            <StyledTableHeadCell sx={{ textAlign: 'center' }}>Recipient Email</StyledTableHeadCell>
                            {backendUserId && (
                                <StyledTableHeadCell sx={{ textAlign: 'center' }}>Backend User</StyledTableHeadCell>
                            )}
                            {scanningUserId && (
                                <StyledTableHeadCell sx={{ textAlign: 'center' }}>Scanning User</StyledTableHeadCell>
                            )}
                            <StyledTableHeadCell>Created</StyledTableHeadCell>
                            <StyledTableHeadCell>Type</StyledTableHeadCell>
                            <StyledTableHeadCell>Reason</StyledTableHeadCell>
                            <StyledTableHeadCell sx={{ textAlign: 'center' }}>Actions</StyledTableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredStatuses.map((status, index) => {
                            const deleteProps = getDeleteProps(status);
                            return (
                                <StyledTableRow key={index}>
                                    <TableCell sx={{ textAlign: 'center' }}>{status.email}</TableCell>
                                    {backendUserId && (
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            <Tooltip title="View Backend User" arrow>
                                                <span>
                                                    <Link to={`/backend_users/${backendUserId}/show`}>
                                                        <Visibility color="primary" />
                                                    </Link>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    )}
                                    {scanningUserId && (
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            <Tooltip title="View Scanning User" arrow>
                                                <span>
                                                    <Link to={`/scanning_users/${scanningUserId}/show`}>
                                                        <Visibility color="primary" />
                                                    </Link>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    )}
                                    <TableCell>{status.created || '-'}</TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography sx={{ flexGrow: 1 }}>{renderName(status.type)}</Typography>
                                            <Tooltip title={getTooltipText(status.type)} arrow placement="top">
                                                <Info fontSize="small" color="action" />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{status.reason || getReason(status)}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        {permissionCheck('sendgrid', 'write')
                                            ? (deleteProps ? <DeleteActionButton {...deleteProps} /> : '-')
                                            : (
                                                <Tooltip title={'You do not have permission to delete'} arrow placement="top">
                                                    <Info fontSize="small" color="error" />
                                                </Tooltip>
                                            )}
                                    </TableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <StyledContainer component={Paper} elevation={3}>
            <Title title="SendGrid Status Lookup" />
            <Typography variant="h5" sx={{ fontWeight: 600, mb: 4, color: theme.palette.text.primary }}>
                SendGrid Status Lookup
            </Typography>
            <Box sx={{ width: '100%', maxWidth: 400, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <StyledEmailField
                        label="Recipient Email"
                        variant="outlined"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        size="small"
                        fullWidth
                    />
                    <Button label="Search" variant="contained" color="primary" onClick={handleSearch} sx={{ borderRadius: '8px', textTransform: 'none', px: 3 }} />
                    {input && <ClearButton label="Clear" variant="outlined" onClick={handleClear} />}
                </Box>
                <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'center' }}>
                    Search to view and manage SendGrid statuses for an email.
                </Typography>
            </Box>
            {email ? renderStatuses() : (
                <Typography variant="body1" sx={{ mt: 4, color: theme.palette.text.secondary, fontStyle: 'italic' }}>
                    Enter a recipient's email address above to get started.
                </Typography>
            )}
        </StyledContainer>
    );
};
import React from 'react'
import GetAppIcon from '@mui/icons-material/GetApp'
import MapIcon from '@mui/icons-material/Map';
import { CircularProgress, TextField, Button, IconButton, Table, TableBody, TableRow, TableCell, Paper, Container, Typography } from '@mui/material'
import BackspaceIcon from '@mui/icons-material/Backspace';
import dataProvider from '../api/DataProvider';

export class TripSnapshotForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      clientKey: '',
      loadId: '',
      snapshotId: '',
      dateFilter: '',
      timeFilter: '',
      token: localStorage.getItem("token"),
      apiUrl: (window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
        (process && process.env && process.env.REACT_APP_API_URL),
      jsonData: {},
      includeGeometry: true,
      loading: false,
      rendered: false,
      snapshotData: null
    };
  }

  handleChildTabChange = () => {
    this.props.onTabChange("Trip Planner")
  };
  handleSnapShotData = (snapshotData) => {
    this.props.onSnapShotDataReceived(snapshotData);
  }
  handleReasonCode = (reasonCode) => {
    this.props.onReasonCodeReceived(reasonCode);
  }

  componentDidMount() {
    const searchCriteria = localStorage.getItem('searchCriteria');
    if (searchCriteria) {
      const { username, clientKey, loadId, dateFilter, timeFilter } = JSON.parse(searchCriteria);
      this.setState({
        username: username || '',
        clientKey: clientKey || '',
        loadId: loadId || '',
        dateFilter: dateFilter || '',
        timeFilter: timeFilter || '',
      });
    }
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    }, () => {
      // Save the updated values to localStorage
      const { username, clientKey, loadId, dateFilter, timeFilter } = this.state;
      const searchCriteria = JSON.stringify({ username, clientKey, loadId, dateFilter, timeFilter });
      localStorage.setItem('searchCriteria', searchCriteria);
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    var valid = true
    var snapshot = false
    this.setState({ loading: true });
    this.setState({ rendered: true })

    if (this.state.clientKey == null || this.state.clientKey.length == 0) {
      alert("Client Key required")
      valid = false
    }
    else if (this.state.username == null || this.state.username.length == 0) {
      alert("Username required")
      valid = false
    }
    else if (this.state.loadId == null || this.state.loadId.length == 0) {
      alert("Load ID required")
      valid = false
    }

    if (valid) {
      let type = 'GET_LIST';
      let filters = { clientKey: this.state.clientKey, username: this.state.username, loadId: this.state.loadId };
  
      if (this.state.snapshotId && this.state.snapshotId.length > 0) {
        type = 'GET_ONE';
        filters.uuid = this.state.snapshotId;
      }
  
      const {data: responseData} = await dataProvider(type, 'trip_plans', { filter: filters }, `Bearer ${localStorage.getItem('token')}`);
      if(!responseData || responseData.length === 0){
        alert(`Unable to find load with the given load id and username combination`);
        this.setState({ loading: false });
      }
      else{
        this.setState({ jsonData: responseData, loading: false });
        this.props.onUpdateSnapShotResults(responseData);
      }
    } else {
      this.setState({ loading: false });
    }    
  }

  usernameChange = (event) => {
    this.setState({ username: event.target.value });
  }
  clientKeyChange = (event) => {
    this.setState({ clientKey: event.target.value });

  }
  loadIdChange = (event) => {
    this.setState({ loadId: event.target.value });
  }
  snapshotIdChange = (event) => {
    this.setState({ snapshotId: event.target.value });
  }
  includeGeometryChange = (event) => {
    this.setState({ includeGeometry: event.target.checked });
  }

  render() {
    const sanitizeObject = (object, object_properties) => {
      for (var key in object) {
        if (object.hasOwnProperty(key) && object_properties.includes(key) == false) {
          delete object[key]
        }
      }
    }
    const sanitizeStop = (stop, stop_properties, truck_properties, route_options_properties) => {
      sanitizeObject(stop, stop_properties)
      if (stop.truck != null) {
        sanitizeObject(stop.truck, truck_properties)
      }
      if (stop.route_options != null) {
        sanitizeObject(route_options, route_options_properties)
      }
    }

    // a heuristic approach to make copying and pasting load json into airtable easier for testing
    const extractLoadJson = async (uuid) => {
      let truck_properties = ["type", "height", "width", "length", "limited_weight", "weigth_per_axle", "trailer_count", "tunnel_category", "hazardous_types", "traffic_rerouting"];
      let stop_properties = ["stop_number", "stop_type", "current", "name", "address", "city", "state", "postal_code", "location", "identifiers", "truck", "route_options"];
      let load_properties = ["id", "display_identifier", "sort", "order_number", "load_status", "load_status_label", "active", "current", "trip_planner_enabled", "route_options", "stops", "consignee", "truck", "shipper", "route_options"];
      let route_options_properties = ["avoid_dirt_roads", "avoid_toll_roads", "avoid_tunnels", "hide_total_miles"];
    
      let response = await dataProvider('GET_ONE', 'trip_plans', {filter:{clientKey: this.state.clientKey, username: this.state.username, loadId: this.state.loadId, uuid: uuid}}, `Bearer ${localStorage.getItem('token')}`)
      const loadData = response.data;
      if(!loadData || loadData.length === 0){
        alert(`Request failed. Status: ${response.status}`);
        return null;
      }
      else {
        let resp = loadData;
        let load = resp.load;
        if (load) {
          sanitizeObject(load, load_properties);
          if (load.stops != null) {
            for (let s = 0; s < load.stops.length; s++) {
              sanitizeStop(load.stops[s], stop_properties, truck_properties, route_options_properties);
            }
          }
          if (load.shipper != null) {
            sanitizeStop(load.shipper, stop_properties, truck_properties, route_options_properties);
          }
          if (load.consignee != null) {
            sanitizeStop(load.consignee, stop_properties, truck_properties, route_options_properties);
          }
          if (load.truck != null) {
            sanitizeObject(load.truck, truck_properties);
          }
          if (load.route_options != null) {
            sanitizeObject(route_options, route_options_properties);
          }
        }
        return JSON.stringify([load]);
      }
    };

    const retrieveSnapshotJSON = async (uuid, isForGpx) => {
      let response = await dataProvider('GET_ONE', 'trip_plans', {filter:{clientKey: this.state.clientKey, username: this.state.username, loadId: this.state.loadId, uuid: uuid}}, `Bearer ${localStorage.getItem('token')}`)
      let snapshot = response.data;
      if(!snapshot || snapshot.length === 0){
        alert(`Request failed. Status: ${response.status}`);
        return null;
      }
      else {
        if (isForGpx) return snapshot
        else return JSON.stringify(snapshot);
      }
    };

    //Helper function to order the stops based on stop number. 
    //This also takes a shipper and/or consignee into account.
    const getSortedStops = (stops, load) => {
      const hasShipper = load && load.shipper && (load.shipper !== null);
      const hasConsignee = load && load.consignee && (load.consignee !== null);

      const compareStops = (a, b) => a.stop_number - b.stop_number;

      if (hasShipper && hasConsignee) {
        // Sort all stops including shipper and consignee
        const sortedStops = [load.shipper, ...stops, load.consignee].sort(compareStops);
        return sortedStops;
      } else if (hasShipper) {
        // Only shipper exists, sort stops and include shipper
        return [load.shipper, ...stops].sort(compareStops);
      } else if (hasConsignee) {
        // Only consignee exists, sort stops and include consignee
        return [...stops, load.consignee].sort(compareStops);
      } else {
        // Neither shipper nor consignee exists, sort only based on stop_number
        return stops.sort(compareStops);
      }
    };

    const ResultItem = ({ item }) => (
      <Table component={Paper} elevation={3} style={{ margin: "10px" }}>
        <TableBody>
          <TableRow hover selected>
            <TableCell><b>UUID</b></TableCell>
            <TableCell><b>{item.uuid}</b></TableCell>
            <TableCell><b>Snapshot JSON</b> <DownloadField data={item.uuid} /></TableCell>
            <TableCell><b>Load JSON</b> <LoadDownloadField data={item.uuid} /></TableCell>
            <TableCell><b>GPX File</b> <GPXDownloadField data={item.uuid} /></TableCell>
            <TableCell><b>View Trip Plan</b> <TripPlanField data={item.uuid} reasonCodes={item.reason_codes.join(", ")} /> </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><b>Reason Codes</b></TableCell>
            <TableCell>{item.reason_codes.join(", ")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><b>Generated At</b></TableCell>
            <TableCell>{item.generated_at}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );

    const RenderResults = ({ jsonData, loading }) => {
      if (loading) {
        return (
          <div style={{ margin: "50px" }}>
            <CircularProgress />
          </div>
        );
      } else if (jsonData && jsonData.length > 0) {
        if (this.state.timeFilter && this.state.dateFilter) {
          let searchedDate = new Date(`${this.state.dateFilter}T${this.state.timeFilter}+00:00`)
          let hr = searchedDate.getHours();

          // Create separate Date objects for plusTwo and minusTwo
          let plusTwoHours = new Date(`${this.state.dateFilter}T${this.state.timeFilter}+00:00`);
          let minusTwoHours = new Date(`${this.state.dateFilter}T${this.state.timeFilter}+00:00`);

          let addhr = hr + 2;
          plusTwoHours.setHours(addhr);
          let subhr = hr - 2;
          minusTwoHours.setHours(subhr);
          let filteredData = [];
          jsonData.map((item, index) => {
            // Input datetime string
            let datetimeString = item.generated_at;
            // Parse the datetime string into a Date object
            let generatedAt = new Date(datetimeString);
            if (generatedAt >= minusTwoHours && generatedAt <= plusTwoHours) {
              filteredData.push(item);
            }
          });
          return (
            <Container >
              <div>
                {filteredData.map((item, index) => (
                  <div key={index}>
                    <h5></h5>
                    <ResultItem item={item} />
                  </div>
                ))}
              </div>
            </Container>
          );
        } else if (this.state.dateFilter) {
          let searchedDate = new Date(`${this.state.dateFilter}T00:00:00`);
          let searchedDay = searchedDate.getDate();
          let searchedMonth = searchedDate.getMonth();
          let searchedYear = searchedDate.getFullYear();


          let filteredData = [];
          jsonData.map((item, index) => {
            // Input datetime string
            let datetimeString = item.generated_at;
            // Parse the datetime string into a Date object
            let generatedAt = new Date(datetimeString)
            let generatedAtDay = new Date(generatedAt).getDate();
            let generatedAtMonth = new Date(generatedAt).getMonth();
            let generatedAtYear = new Date(generatedAt).getFullYear();
            if (generatedAtYear === searchedYear && generatedAtMonth === searchedMonth && generatedAtDay === searchedDay) {
              filteredData.push(item);
            }
          });
          return (
            <Container >
              <div>
                {filteredData.map((item, index) => (
                  <div key={index}>
                    <h5></h5>
                    <ResultItem item={item} />
                  </div>
                ))}
              </div>
            </Container>
          );
        }
        else {
          return (
            <Container >
              <div>
                {jsonData.map((item, index) => (
                  <div key={index}>
                    <h5></h5>
                    <ResultItem item={item} />
                  </div>
                ))}
              </div>
            </Container>
          );
        }
      }
    };
    const DownloadField = (uuid) => {
      const handleDownloadClick = async () => {
        const loadJson = await retrieveSnapshotJSON(uuid.data, false);
        if (loadJson) {
          let parsedData = JSON.parse(loadJson);
      
          // Transform geometry for each stop
          parsedData.trip_plan.stops = parsedData.trip_plan.stops.map(stop => {
            // Transform stop.geometry
            if (stop.geometry) {
              stop.geometry = stop.geometry.map(point => ({
                lat: point.latitude,
                lng: point.longitude
              }));
            }
      
            // Transform stop.geometry_segments
            if (stop.geometry_segments) {
              stop.geometry_segments = stop.geometry_segments.map(segment => {
                if (segment.geometry) {
                  segment.geometry = segment.geometry.map(point => ({
                    lat: point.latitude,
                    lng: point.longitude
                  }));
                }
                return segment;
              });
            }
      
            return stop;
          });
      
          // Convert the modified object back to JSON
          const modifiedJson = JSON.stringify(parsedData, null, 2);
      
          // Create and download the updated JSON file
          const blob = new Blob([modifiedJson], { type: 'application/json' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.state.loadId}_SnapshotData.json`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      };      
      return (<div style={{ "display": "inline" }} id="snapshot-download"><IconButton color="primary" size="large" onClick={handleDownloadClick}><GetAppIcon /></IconButton></div>);
    }
    const LoadDownloadField = (uuid) => {
      const handleDownloadClick = async () => {
        const loadJson = await extractLoadJson(uuid.data);
        if (loadJson) {
          const blob = new Blob([loadJson], { type: 'application/json' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.state.loadId}.json`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      };
      return (<div style={{ "display": "inline" }} id="load-download"><IconButton color="primary" size="large" onClick={handleDownloadClick}><GetAppIcon /></IconButton></div>);
    };

    const GPXDownloadField = (uuid) => {
      const handleDownloadClick = async () => {
        const loadJson = await retrieveSnapshotJSON(uuid.data, true);
        if (loadJson) {
          //Create a GPX File utilizing all geometry segments
          const geometryPoints = loadJson.trip_plan.stops.flatMap(stop => {
            if (stop.geometry_segments) {
              return stop.geometry_segments.flatMap(segment => {
                if (segment.geometry) {
                  return segment.geometry;
                }
                return [];
              });
            }
            return [];
          });
          const stopArray = getSortedStops(loadJson.load.stops, loadJson.load)

          const gpxContent =
            `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
  <gpx xmlns="http://www.topografix.com/GPX/1/1" xmlns:gpxx="http://www.garmin.com/xmlschemas/GpxExtensions/v3" xmlns:gpxtpx="http://www.garmin.com/xmlschemas/TrackPointExtension/v1" creator="mapstogpx.com" version="1.1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd http://www.garmin.com/xmlschemas/GpxExtensions/v3 http://www.garmin.com/xmlschemas/GpxExtensionsv3.xsd http://www.garmin.com/xmlschemas/TrackPointExtension/v1 http://www.garmin.com/xmlschemas/TrackPointExtensionv1.xsd">
    <wpt lat="${stopArray[0].location.latitude}" lon="${stopArray[0].location.longitude}">
      <name>${stopArray[0].name}</name>
    </wpt>
    <wpt lat="${stopArray[stopArray.length - 1].location.latitude}" lon="${stopArray[stopArray.length - 1].location.longitude}">
      <name>${stopArray[stopArray.length - 1].name}</name>
    </wpt>
    <trk>
      <name>${loadJson.load.order_number}</name>
      <trkseg>
      ${geometryPoints.map((point, index) =>
              `${'\t'}<trkpt lat="${point.latitude}" lon="${point.longitude}"><name>TP${index}</name></trkpt>`
            ).join('\n\t')}
      </trkseg>
    </trk>
  </gpx>`;
          const blob = new Blob([gpxContent], { type: 'application/gpx+xml' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.state.loadId}.gpx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      }
      return (<div style={{ "display": "inline" }} id="gpx-download"><IconButton color="primary" size="large" onClick={handleDownloadClick}><GetAppIcon /></IconButton></div>)
    }

    const TripPlanField = ({ data, reasonCodes }) => {
      const handleTripPlanClick = async () => {
        const json = await retrieveSnapshotJSON(data);
        if (json) {
          this.setState({ snapshotData: json });
          this.props.onSnapShotDataReceived([json]);
          this.props.onReasonCodeReceived(reasonCodes);
          this.handleChildTabChange();
        }
      };
      return (
        <div style={{ display: "inline" }} id="trip-plan-field">
          <IconButton id='trip-planner-btn' color="primary" size="large" onClick={handleTripPlanClick}>
            <MapIcon />
          </IconButton>
        </div>
      );
    };



    const formStyle = {
      marginLeft: "300px",
    }
    const inputStyle = {
      margin: "8px",
    }
    const { jsonData, loading } = this.state;

    const handleClearResults = (e) => {
      e.preventDefault();
      localStorage.removeItem('searchCriteria')
      this.props.onSnapShotDataReceived(null)
      this.props.onUpdateSnapShotResults(null);
      this.setState({ clientKey: '' })
      this.setState({ username: '' })
      this.setState({ loadId: '' })
      this.setState({ dateFilter: '' })
      this.setState({ timeFilter: '' })
      this.setState({ jsonData: null });
      this.setState({ rendered: false })
    };
    const handleDateClear = () => {
      this.setState({ dateFilter: '' })
      localStorage.removeItem('searchCriteria')
      const { username, clientKey, loadId, timeFilter } = this.state;
      const searchCriteria = JSON.stringify({ username, clientKey, loadId });
      localStorage.setItem('searchCriteria', searchCriteria);
    }
    const handleTimeClear = () => {
      this.setState({ timeFilter: '' })
      localStorage.removeItem('searchCriteria')
      const { username, clientKey, loadId, dateFilter } = this.state;
      const searchCriteria = JSON.stringify({ username, clientKey, loadId, dateFilter });
      localStorage.setItem('searchCriteria', searchCriteria);
    }
    return (
      <div >
        <h2>Retrieve Trip Snapshot</h2>
        <Container>
          <form onSubmit={this.submitHandler}>
            <div style={inputStyle}>
              <TextField
                id='clientKey-input'
                variant="outlined"
                label="Client Key"
                name='clientKey'
                size="small"
                value={this.state.clientKey}
                onChange={this.handleInputChange} />
            </div>
            <div style={inputStyle}>
              <TextField
                id='username-input'
                variant="outlined"
                label="Username"
                name='username'
                size="small"
                value={this.state.username}
                onChange={this.handleInputChange} />
            </div>
            <div style={inputStyle}>
              <TextField
                id='loadId-input'
                variant="outlined"
                label="Load ID"
                name='loadId'
                size="small"
                value={this.state.loadId}
                onChange={this.handleInputChange} />
            </div>
            {this.state.rendered && (
              <>
                <div style={inputStyle}>
                  <Typography style={{ fontSize: 'smaller' }}>Filter By Date: </Typography>
                  <TextField
                    id='date-input'
                    variant="outlined"
                    helperText='Please select a date'
                    name='dateFilter'
                    size="small"
                    type='date'
                    value={this.state.dateFilter}
                    onChange={this.handleInputChange} />
                  {this.state.dateFilter && <IconButton id='date-clear' color="primary" size="large" onClick={handleDateClear}><BackspaceIcon /></IconButton>}
                </div>
                {this.state.dateFilter && (
                  <div style={inputStyle}>
                    <Typography style={{ fontSize: 'smaller' }}>Filter By Time (+/- 2 Hours): </Typography>
                    <TextField
                      id='time-input'
                      variant="outlined"
                      name='timeFilter'
                      size="small"
                      type='time'
                      value={this.state.timeFilter}
                      onChange={this.handleInputChange}
                    />
                    {this.state.timeFilter && <IconButton id='time-clear' color="primary" size="large" onClick={handleTimeClear}><BackspaceIcon /></IconButton>}
                  </div>
                )}
              </>
            )}
            <div style={inputStyle}>
              <Button type='submit' variant="contained" color="primary">Send Request</Button>
              &emsp;
              {(jsonData && jsonData.length > 0) ?
                <Button id='clear-results' variant='contained' onClick={handleClearResults}>Clear Results</Button>
                : <Button id='clear-fields' variant='contained' onClick={handleClearResults}>Clear Fields</Button>
              }
              <hr />
            </div>
          </form>
        </Container>
        <RenderResults jsonData={jsonData} loading={loading} />
      </div>
    );
  }
}

import React from "react";
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Root = styled('div')(({ theme }) => ({
  paddingLeft: '2em',
  paddingRight: '2em',
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
}));

const Subheading = styled('h4')(({ theme }) => ({
  marginBottom: '0.5em',
  marginTop: '1.5em',
}));

const Subgroup = styled('div')(({ theme }) => ({
  marginBottom: '1.5em',
}));

const DocItem = (props) => {
  const title = props.title || '';
  const body = props.children || '';
  return (
    <Accordion style={props.style}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Heading>{title}</Heading>
      </AccordionSummary>
      <AccordionDetails>
        {/* Change Typography to render as a div to avoid <ul> inside <p> */}
        <Typography component="div">
          {body}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const DocGroup = (props) => {
  const title = props.title || '';
  const children = props.children || '';
  const width = props.width || '35em';
  const style = width + props.style
  return (
    <Root style={{ style }}>
      <h1>{title}</h1>
      {children}
    </Root>
  );
};

const DocSubgroup = (props) => {
  const title = props.title || '';
  const children = props.children || '';

  return (
    <Subgroup>
      <Subheading>{title}</Subheading>
      {children}
    </Subgroup>
  );
};

export {
  DocItem,
  DocGroup,
  DocSubgroup,
};
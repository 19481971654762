import React from "react";
import Typography from '@mui/material/Typography';
import { DocGroup, DocItem } from "../components/DocFields";
import { ArrowRightSharp } from "@mui/icons-material";

export const PluggableDebugDoc = () => (
    <DocGroup title="PR Debug Guide">
        <DocItem title="Getting Started">
            <Typography component="div">
                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '0.5em' }}>Control Panel</div>
                <div>This will contain all buttons needed for debugging the pluggable route.</div>
                <div>The <b>Guide</b>, <b>Map Legend</b>, <b>and Clear Changes</b> buttons will always be available.</div>
                <div>Below, you will see the <b>Bad Sections</b> portion containing a list of cards containing bad sections along the pluggable route.</div>
                <hr />
                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '0.5em' }}>Sections</div>
                <div>A section, is a portion of a route containing trace points sent by the customer's pluggable router.</div>
                <div>Sometimes, the customer's router will send trace points that do not align with HERE's road network. These bad sections cause import failures.</div>
                <hr />
                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '0.5em' }}>Map Legend</div>
                <div>If at any point you are confused about a certain symbol, simply click the <b>Map Legend</b> button to get a description of all relevant symbols.</div>
                <hr />
                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '0.5em' }}>Zooming In/Out</div>
                <div>Click on a bad section card to zoom in/out of a broken section.</div>
                <div>When zoomed in, you will see <b style={{ color: 'blue' }}>numbered blue circles</b> called <b>trace points</b>.</div>
                <div>Trace points correspond to geometry points (lat/lng) returned by the customer's pluggable router.</div>
                <hr />
                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '0.5em' }}>Street View</div>
                <div>At times, it may be helpful to get "eyes on the ground" and see the street view of a bad section.</div>
                <div>For each section, you can click the <b>Street View</b> button.</div>
                <div>This button will open a separate tab to the Google Street view of the bad section's starting location.</div>
                <hr />
                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '0.5em' }}>Adjusting Trace Points</div>
                <div><b>Click and drag</b> the desired trace point to a more accurate location.</div>
                <div style={{ fontWeight: 'bold', fontSize: 'small', marginTop: '0.5em' }}>NOTE: For better precision, zoom in to <b>50-100m</b> before making adjustments.</div>
                <hr />
                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '0.5em' }}>Possible Reasons to Adjust Points:</div>
                <ul>
                    <li>If a trace point is off the road network.</li>
                    <li>If two trace points are too far apart.</li>
                </ul>
                <hr />
                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '0.5em' }}>Multiple Broken Sections</div>
                <div>Some routes return multiple broken sections and you will need to adjust trace points for each section.</div>
                <div>This tool keeps track of all changes for every section you make.</div>
                <hr />
                <div>When you are finished with your adjustments, you will see a <b>Test Section</b> button appear. Read more on this button under <b>Test Section Results</b>.</div>
                <div>Repeat the adjustment process for any other broken sections.</div>
                <hr />
                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '0.5em' }}>When NOT to Apply a Patch</div>
                <ul>
                    <li>The route failed due to <b>road closures</b> or <b>construction</b>.</li>
                    <li>More than <b>10-15 trace points</b> are too far off the road network.</li>
                    <li>The patch would <b>create a new route</b> rather than fixing the existing/desired one.</li>
                    <li>The original route from the <b>pluggable router is unclear.</b></li>
                    <li>The issue involves <b>HERE-imposed restrictions</b>, which cannot be overridden.</li>
                    <li>If it is a <b>temporary solution</b>—these must go through Product first.</li>
                </ul>
                <div style={{ fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
                    Use your best judgment. If unsure, please ask for a second opinion.
                </div>
                <hr />
            </Typography>
        </DocItem>
        <DocItem title="Test Section Results">
            <Typography component="div">
                <div>After adjusting a trace point for a broken section, a <b>Test Section</b> button will appear.</div>
                <hr />
                <div>This button tests whether your changes align with HERE's road network.</div>
                <div>Click <b>Test Section</b> to validate your changes will successfully comply with HERE's road network.</div>
                <div style={{ marginTop: '0.5em' }}><b>What happens next?</b></div>
                <ul>
                    <li>If the import is successful, a <b style={{ color: 'green' }}>green polyline</b> will appear across the section.
                        <ul>
                            <li>This section is now ready to have it's patch tested.</li>
                        </ul>
                    </li>
                    <li>If the import fails, a popup will notify you of the failure.
                        <ul>
                            <li>Further adjustments are needed.</li>
                            <li>A <b>View Notices</b> button will appear—click it to see more details on why the import failed.</li>
                        </ul>
                    </li>

                </ul>
                <hr />
            </Typography>
        </DocItem>
        <DocItem title="Apply Patches">
            <Typography component="div">
                <div>If a section is successfully tested, you will see a green checkmark and message indicating so.</div>
                <div>You also will see a new <b>Apply Patch to Section</b> button appear.</div>
                <div>Click the <b>Apply Patch to Section</b> button to apply the patch.</div>
                <hr />
                <div>NOTE: This step will only apply one patch for <b>the corresponding section</b>.</div>
                <hr />
            </Typography>
        </DocItem>
        <DocItem title="Patch Commands">
            <Typography component="div">
                <div>If the patch was successfully applied to the section, you will see a green checkmark and message indicating so.</div>
                <div>You also will see an important section appear containing a button for the patch UUID.</div>
                <hr />
                <div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 'larger', color: '#d32f2f' }}>This step is HIGHLY important.</div>
                <div style={{ marginTop: '0.5em' }}><b>What to do next:</b></div>
                <ul>
                    <li>Click the UUID button to view and copy the corresponding command.</li>
                    <li>Escalate the copied command to Engineering via the normal escalation process so an engineer can enable the patch.
                        <div style={{ display: 'flex', flexDirection: 'row' }}>(Tier 1 Support <ArrowRightSharp /> Tier 2 Support <ArrowRightSharp /> Product <ArrowRightSharp /> Engineering)</div>
                    </li>
                </ul>
                <hr />
                <div><b>Although the patches have been applied, they are internally disabled by default.</b></div>
                <div>We must enable them.</div>
                <div>Engineering can execute the command to officially enable the patch.</div>
                <hr />
                <div style={{ marginTop: '0.5em', fontWeight: 'bold', textAlign: 'center' }}>
                    After copying all commands & escalating to Engineering, congrats!
                    <br />You have officially debugged and fixed the broken pluggable route!
                </div>
                <hr />
                <hr />
            </Typography>
        </DocItem>
        <DocItem title="Restarting">
            <Typography component="div">
                <div>After making changes, you can click the <b>Clear Changes</b> button to revert to the initial data state.</div>
                <hr />
                <div style={{ marginTop: '0.5em' }}><b>Clicking Clear Changes will:</b></div>
                <ul>
                    <li>Move all adjusted points back to their original positions.</li>
                    <li>Remove all <b style={{ color: 'green' }}>green polylines</b>.</li>
                    <li>Zoom the map back out.</li>
                    <li>Remove any testing/patching progress for all sections.</li>
                </ul>
                <hr />
            </Typography>

        </DocItem>
    </DocGroup>
)


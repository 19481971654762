import React from "react";
import {
  useRecordContext,
  Create,
  SimpleForm,
  TextInput,
  required,
  NotFound,
  useCreatePath,
  useGetList,
  Loading,
} from "react-admin";
import { Button as MaterialButton, Typography } from "@mui/material";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import LockResetIcon from '@mui/icons-material/LockReset';
import DraftsTable from "../components/DraftsTable";


export const DeleteUserButton = (props) => {
  const createPath = useCreatePath();
  return (
    <MaterialButton
      id="delete-user-button"
      color="primary"
      component={RouterLink}
      style={{ fontSize: "0.8125rem", padding: ".15rem" }}
      to={
        createPath({
          resource: "scanning_user_delete_drafts",
          type: "create",
        }) + `?email=${props.email}`
      }
    >
      <LockResetIcon
        style={{ fontSize: "1.25rem", paddingRight: "0.5rem" }}
      />
      Delete User
    </MaterialButton>
  );
};

export const ScanningUserDeleteDraftsField = (email) => {
  const record = useRecordContext();

  const { data, isLoading, error } = useGetList(
    "scanning_user_delete_drafts",
    {
      filter: { "event.path": `/${record.id}` },
      pagination: { page: 1, perPage: 10 },
      meta: email
    }
  );

  if (isLoading) { return <Loading /> }
  if (error) { return <p>ERROR</p> }
  return (
    <DraftsTable data={data} resource={"scanning_user_delete_drafts"}/>
  );
};

const validateDraftCreation = (email, values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "You must enter the user's email to confirm";
  } else if (email !== values.email) {
    errors.email = "The email entered must match the user's email exactly";
  }
  return errors;
}

export const ScanningUserDeleteDraftsCreate = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const createPath = useCreatePath();
  if (email == null) return <NotFound />;
  return (
    <Create
      title={`Delete Scanning User: ${email}`}
      redirect={createPath({ resource: "scanning_users", id: email, type: "show" }) + "?delete_draft_created="}
    >
      <SimpleForm validate={(values) => validateDraftCreation(email, values)}>
        <Typography variant="h5">You are about to delete the Drive Axle account for: <b>{email}</b></Typography>
        <br/>
        <Typography variant="h6">Please type the user's email exactly to confirm:</Typography>
        <TextInput label="User Email" source="email" resettable validate={required()} />
        <input hidden defaultValue={email} source="email" />
      </SimpleForm>
    </Create>
  );
};
import * as Sentry from "@sentry/browser";

export const sentryErrorHandler = (error, resource = 'Unknown Resource', type = 'not-specified') => {
    const sentryError = error instanceof Error 
        ? error 
        : typeof error === 'string' 
            ? new Error(error) 
            : new Error(JSON.stringify(error) || `${resource} error`);    
    sentryError.name = type !== 'not-specified' ? `${resource} [${type}] Request Failed`: `${resource} Request Failed`;
    Sentry.captureException(sentryError, {
        tags: { resource }
    });
}

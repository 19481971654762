import React from "react";
import { DocItem, DocGroup, DocSubgroup } from "../components/DocFields";

const PerLoadBillingCreateDoc = () => (
  <Box style={{ width: '50%' }}>
    <DocGroup title="More Info">
      <DocItem title="Billing Account Name">
        This is what the House Billing Account will be called
        <ul>
          <li>The name of the company</li>
          <li><b>CANNOT</b> be changed later</li>
        </ul>
      </DocItem>
      <BasePerLoadBillingStructure />
    </DocGroup>
  </Box>
)

const PerLoadBillingEditDoc = () => (
  <DocGroup title="More Info (Per-Load Billing)">
    <DocItem title="Billing Account External ID">
      This is the UUID used to identify the specific Billing Account
    </DocItem>
    <BasePerLoadBillingStructure />
  </DocGroup>
)

const BasePerLoadBillingStructure = () => (
  <>
    <DocItem title="Invoice Line Item Prefix">
      This determines the name of the invoice line item.
      <ul>
        <li>The name of the company</li>
        <li>Example: <b>Eleos Technologies</b></li>
      </ul>
    </DocItem>

    <DocItem title="Contract Structure Code">
      The code to be used for the Contract Structure
      <ul>
        <li>Usually the same as the Price Sheet Code</li>
        <li>Example: <b>ELEOS-{new Date().getFullYear()}</b></li>
      </ul>
    </DocItem>

    <DocItem title="Price Sheet Code">
      The code to be used for the Price Sheet
      <ul>
        <li>Usually the same as the Contract Structure Code</li>
        <li>Example: <b>ELEOS-{new Date().getFullYear()}</b></li>
      </ul>
    </DocItem>

    <DocItem title="Product Prefix">
      The value prefixed to the code of each product
      <ul>
        <li>The name of the company separated by dashses or an abbreviation</li>
        <li>Example: <b>ELEOS-TECH-TRUCKING</b> or <b>ETT</b></li>
        <li>Used to make each product unique to a contract</li>
      </ul>
    </DocItem>

    <DocItem title="Valid From">
      The date at which this Contract becomes valid
      <ul>
        <li>Must be first of the month</li>
      </ul>
    </DocItem>

    <DocItem title="Contract Anniversary">
      The date used for assessing annual products (e.g. maintenance fees)
    </DocItem>

    <DocItem title="Max Fee Multiplier">
      The value multiplied against the monthly usage fees to determine the max fees
      <ul>
        <li>Defaults to 1.5</li>
      </ul>
    </DocItem>

    <DocSubgroup title="App Maintenance">
      <DocItem title="Fee">
        Fee in dollars for app maintenance
      </DocItem>

      <DocItem title="Period">
        How often the customer is charged for app maintenance
      </DocItem>
    </DocSubgroup>

    <DocSubgroup title="Companion">
      <DocItem title="Monthly Usage Fee">
        Monthly Usage Fee in dollars for companion app usage
      </DocItem>

      <DocItem title="Per-Load Usage Fee">
        Per-Load Usage Fee in dollars for companion app usage
      </DocItem>

      <DocItem title="Per-Load Usage Fee Minimum">
        Minimum Per-Load Usage Fee in dollars for companion app usage
      </DocItem>
    </DocSubgroup>

    <DocSubgroup title="In-Cab">
      <DocItem title="Monthly Usage Fee">
        Monthly Usage Fee in dollars for In-Cab app usage
      </DocItem>

      <DocItem title="Per-Load Usage Fee">
        Per-Load Usage Fee in dollars for In-Cab app usage
      </DocItem>

      <DocItem title="Per-Load Usage Fee Minimum">
        Minimum Per-Load Usage Fee in dollars for In-Cab app usage
      </DocItem>
    </DocSubgroup>

    <DocSubgroup title="Telematics Integration">
      <DocItem title="Monthly Usage Fee (optional)">
        Monthly Usage Fee in dollars for telematics integration
      </DocItem>
    </DocSubgroup>

    <DocSubgroup title="Minimum Monthly Payment">
      <DocItem title="Minimum Monthly Payment (optional)">
        The minimum amount in dollars that a customer agrees to pay each month
      </DocItem>
    </DocSubgroup>
  </>
)

export {
  PerLoadBillingCreateDoc,
  PerLoadBillingEditDoc
}
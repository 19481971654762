import React, {useEffect, useState} from "react";
import { useDataProvider, usePermissions, useRecordContext, useUpdate } from "react-admin";
import { Checkbox, Table, TableContainer, TableBody, TableHead, 
  TableRow, TableCell, Button, Switch, FormGroup, FormControl, 
  FormControlLabel, Link, 
  Select,
  InputLabel,
  MenuItem} from "@mui/material";
import { styled } from '@mui/system';
import { useNotify } from 'react-admin';
import { renderIfPermissions } from "../api/permissionManager";
import "./AppButton.css"
import { checkIconReqs, checkLogoReqs, checkImageRequirements } from '../resources/assetChecks';
import { sentryErrorHandler } from "../api/SentryErrorHandler";

const StyledButton = styled(Button)({
  width: '10rem',
  height: '2.5rem',
  marginBottom: '20px'
});

const StyledTableCellButton = styled(Button)({
  '&:hover': {
    background: "#eee",
    textDecoration: 'underline'
  },
  textTransform:'lowercase',
  cursor: "pointer"
});

export const TriggerBuildForm = (props) => {
  const record = useRecordContext();
  const {permissions} = usePermissions();
  const [key, setKey] = useState(record.key);
  const [ios, setIos] = useState(false);
  const [android, setAndroid] = useState(true);
  const [tagName, setTagName] = useState('');
  const [releaseChoice, setReleaseChoice] = useState('');
  const [releases, setReleases] = useState([]);
  const dataProvider = useDataProvider();
  const [update] = useUpdate();
  const notify = useNotify();

  const refreshTags = async () => {
    const {data} = await dataProvider.getList('app_releases', null);
    setReleases(data)
  };

  useEffect(() => {
    refreshTags("android");
  }, []);

  const androidToggle = () => {
    setAndroid(true)
    setIos(false)
    setTagName("")
    releases.forEach((release) => {
      if (release.release_name === releaseChoice) {
        setTagName(release.android_tag)
      }
    })
  }

  const iosToggle = () => {
    setAndroid(false)
    setIos(true)
    setTagName("")
    releases.forEach((release) => {
      if (release.release_name === releaseChoice) {
        setTagName(release.ios_tag)
      }
    })
  }

  const triggerBuild = async (platform) => {
    if (tagName === "") {
      notify("Error: You must select a release.", {type: 'error'});
      return;
    }
    try {
      const {data} = await dataProvider.getOne('app_assets', {id: key});
      const [appIconSuccess, dashboardLogoSuccess, loginLogoSuccess] = await Promise.all([
        checkImageRequirements(data.app_icon_url, checkIconReqs, 'app-icon'),
        checkImageRequirements(data.dashboard_logo_url, checkLogoReqs, 'dashboard-logo'),
        checkImageRequirements(data.login_logo_url, checkLogoReqs, 'login-logo')
      ]);
      if (appIconSuccess && dashboardLogoSuccess && loginLogoSuccess) {
        dataProvider.update('trigger_builds', {id: `${platform}/${tagName}`, data: {}, meta: {clientKey: key}, body:{tag: tagName, platform}})
        .then(() => {
          notify(`Build triggered for ${platform} with tag ${tagName}`, {type: 'success'});
        }).catch(error => {
          notify('Failed to trigger build. Please try again.', {type: 'error'});
          throw error
        })
      }
    } catch (error) {
      sentryErrorHandler(error, 'triggerBuild')
    }
  };

  const handleSubmit = () => {
    if (ios) {
      triggerBuild('ios');
    }
    if (android) {
      triggerBuild('android');
    }
  }

  const renderIosSwitch = () => {
    if (key.length < 4 || key.substring(key.length - 4) !== '-dev') {
      return (
        <FormControlLabel
          control={
            <Switch
              checked={ios}
              onChange={iosToggle}
              name="ios"
              color="primary"
            />
          }
          label="iOS"
        />
      );
    }
  }

  const onTagInputChange = (event) => {
    setReleaseChoice(event.target.value)
    releases.forEach((release) => {
      if (android && release.release_name === event.target.value) {
        setTagName(release.android_tag)
      }
      else if (ios && release.release_name === event.target.value) {
        setTagName(release.ios_tag)
      }
    })
  }

  const renderBuildField = () => {
    let options = releases.map((opts) => opts.release_name)
    return (
      <FormControl fullWidth>
        <InputLabel variant="outlined" id='tag-input-label'>Select a release</InputLabel>
        <Select autoWidth variant="outlined" id="tag-input" labelId="tag-input-label" value={releaseChoice} label='Select a release' onChange={onTagInputChange}>
          {options && options.map((option, i) => (
            <MenuItem key={i} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  
  return (
    <FormGroup style={{ width: "20%" }}>
      {renderIosSwitch()}
      <FormControlLabel
        control={
          <Switch
            checked={android}
            onChange={androidToggle}
            name="android"
            color="primary"
          />
        }
        label="Android"
      />
      <div style={{ width: "100%" }}>
        {renderBuildField()}
        <br />
        {renderIfPermissions(permissions, "apps", "write",
          <Button color="primary" variant="contained" size="medium" sx={{ width: '10rem', height: '2.5rem' }} onClick={handleSubmit}>Trigger Build</Button>
        )}
      </div>
      <br />
      <BuildStatusDisplay client_key={key} />
    </FormGroup>
  )
}


const BuildStatusDisplay = (props) => {
  const [data, setData] = useState([]);
  const dataProvider = useDataProvider();
  const [update] = useUpdate();
  const notify = useNotify()
  const [keys] = useState(
    ["build_status", "is_published", "key", "name", "build_artifacts", "circle_url", "triggered_at", "triggered_by", "tag", "error_info"],
  )

  const checkBuildStatus = async (client_key, setData) => {
    notify("Refreshing build status...")
    // get initial list of builds
    await dataProvider.getList('apps', {id: client_key, meta: {"statuses":true}})
    .then(({data: buildsResponse }) => {
      if(!buildsResponse) return;
      else{
        buildsResponse.map(async (build) => {
          const statusResponse = await getStatus(build.pipeline_uuid);
          if(statusResponse.errors){
            build.build_status = statusResponse.errors;
          }
        })
      }
    })

    // refresh list of builds with updated status
    const {data: refreshedBuildResponse} = await dataProvider.getList('apps', {id: client_key, meta: {"statuses":true}})
    if(!refreshedBuildResponse) return;

    const finalBuilds = await Promise.all(
      refreshedBuildResponse.map(async (build) => {
        if(build.build_status === 'null'){
          const statusResponse = await getStatus(build.pipeline_uuid);
          build.build_status = statusResponse.errors || "pending...";
        }
        return build;
      })
    );
    setData(finalBuilds)
  }

  useEffect(() => {
    checkBuildStatus(props.client_key, setData)
  }, [props.client_key])


  const getStatus = async (uuid) => {
    return await dataProvider.getOne('apps', {id: uuid, meta: {"status":true}})
  }

  const changePublishStatus = async (event, pipeline_uuid) => {
    await update('apps', {id: pipeline_uuid, meta: {"checked": event.target.checked}})
  }

  const renderTableCell = (row, key) => {
    switch (key) {
      case "build_status":
        return (<>{row[key]}</>)
      case "circle_url":
        return (<StyledTableCellButton onClick={() => window.open(row[key])}>{row[key]}</StyledTableCellButton>)
      case "tag":
        return row.branch === null || undefined ? (<>{row[key]}</>) : (<>{row.branch}</>)
      case "build_artifacts":
        const build_files = row[key];
        if (Object.keys(build_files).length === 0) {
          return <div sx={{ whiteSpace: 'nowrap' }}>No Build Files</div>;
        } else {
          return (
            <div sx={{ whiteSpace: 'nowrap' }}>
              {Object.keys(build_files).map((k, i) => (
                <p key={k}>
                  <span>
                    <Link href={build_files[k]} download>{k.slice(0, 3).toUpperCase()} Build File</Link>
                  </span>
                </p>
              ))}
            </div>
          );
        }
    case "is_published":
        const is_published = row[key]
        return (<Checkbox name="published status" defaultChecked={is_published} onChange={(e) => changePublishStatus(e, row['pipeline_uuid'])} />)
      case "error_info":
        const error_info = row[key];
        if (error_info === null || error_info.length === 0) {
          return ("None");
        } else {
          return (
            <>
              {(() => {
                const blob = new Blob([error_info[0]], {
                  type: "text/plain",
                });
                const url = URL.createObjectURL(blob);
                return (
                  <Link
                    href={url}
                    target="_blank"
                    display="block"
                    download="build_error"
                  >
                    Build Error
                  </Link>
                );
              })()}
              </>
          );
        }
      default:
        return (row[key])
    }
  }

  if (data.length === 0) return (<p>no builds yet :(</p>)
  return (
    <div>
      <StyledButton color="primary" variant="contained" onClick={() => checkBuildStatus(props.client_key, setData)}>Refresh</StyledButton>
      <TableContainer>
        <Table>
          <TableHead style={{ textTransform: "uppercase" }} >
            <TableRow>
              {keys.map((key) => (<TableCell key={key}>{key.replaceAll('_', ' ')}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
          {/*Sometimes row is undefined due to states being updated post render. Check that data is not undefined, and is the anticipated structure before assigning keys to avoid unneccessary console warnings.*/}
          {Array.isArray(data) && data.filter(Boolean).map((row, i) => (
            <TableRow key={row?.pipeline_uuid ?? `row-${i}`}>
              {Array.isArray(keys) && keys.filter(Boolean).map((k) => (
                <TableCell key={`${row?.pipeline_uuid ?? `row-${i}`}-${k}`}>
                  {renderTableCell(row, k)}
                </TableCell>
              ))}
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
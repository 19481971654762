import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"

export const HEREMapSVG = (type, text, h = 24, w = 24) => {
    const iconContent = {
        start: (
            <g>
                <path fill="#FF0000" d="M15 3C10.13 3 7 6.13 7 10c0 6.2 7 16 7 16s7-9.8 7-16c0-3.87-3.13-7-7-7z" />
                <text x="15" y="15" fontSize="6pt" fontFamily="Arial" fontWeight="bold" textAnchor="middle" fill="white" dx="-1">
                    {text}
                </text>
            </g>
        ),
        point: (
            <g>
                <circle cx="12" cy="12" r="8" fill="#2e7dff" />
                <text x="12" y="12" fontSize="6pt" fontFamily="Arial" fontWeight="bold" textAnchor="middle" fill="white" dy=".3em">
                    {text}
                </text>
            </g>
        )
    };

    return (
        <svg height={h} width={w} xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle', width: `${w}px`, height: `${h}px` }}>
            {iconContent[type]}
        </svg>
    );
};

export const HEREMapLegend = () => {
    return (
        <Box style={{ border: 'groove', padding: '0.5rem', width: 'max-content', textAlign:'center' }}>
            <Typography variant="h6" sx={{fontWeight:'bold'}}>Map Legend</Typography>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <span
                                style={{
                                    verticalAlign: 'middle',
                                    display: 'inline-block',
                                    width: '4em',
                                    height: '5px',
                                    backgroundColor: 'blue',
                                    marginRight: '8px'
                                }} />
                        </TableCell>
                        <TableCell>Original Section</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span
                                style={{
                                    verticalAlign: 'middle',
                                    display: 'inline-block',
                                    width: '4em',
                                    height: '5px',
                                    backgroundColor: 'red',
                                    marginRight: '8px'
                                }}
                            />
                        </TableCell>
                        <TableCell>Bad Section</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span
                                style={{
                                    verticalAlign: 'middle',
                                    display: 'inline-block',
                                    width: '4em',
                                    height: '5px',
                                    borderBottom: '5px dashed red',
                                    marginRight: '8px'
                                }}
                            />
                        </TableCell>
                        <TableCell>Broken Section</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><span
                                style={{
                                    verticalAlign: 'middle',
                                    display: 'inline-block',
                                    width: '4em',
                                    height: '5px',
                                    backgroundColor: 'green',
                                    marginRight: '8px'
                                }}
                            /></TableCell>        
                        <TableCell>Successful Import</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{textAlign:'center', paddingLeft:'0'}}>{HEREMapSVG('start', '', 24, 24)}</TableCell>         
                        <TableCell>Start of a Broken Section</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{textAlign:'center', paddingLeft:'4px'}}>{HEREMapSVG('point', '', 24, 24)}</TableCell>            
                        <TableCell>Geometry Trace Point</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
}
import { useState } from "react";
import { Button, Confirm, useDataProvider, useListContext, useNotify, useRefresh, useUnselect } from "react-admin";
import { styled } from '@mui/system';

const RedownloadButton = styled(Button)(({ theme }) => ({
    background: '#8AC2EC',
        color: 'black',
        marginLeft: '5px'
  }));

const DocumentRedownloadButton = (props) => {
    const dataProvider = useDataProvider();
    const {resource, selectedIds} = useListContext()
    const unselect = useUnselect(resource)
    const [open, setOpen] = useState(false)
    const refresh = useRefresh();
    const notify = useNotify();

    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    const handleConfirm = async () => {
        const key = regexExp.test(props.filterValues.uuid) ? "customer_uuid" : "client_key";
        const body = JSON.stringify({ ids: selectedIds, [key]: props.filterValues.uuid });
        
        const {data, error} = await dataProvider.update('documents', {id: props.filterValues.uuid, body});
        notify(data && data.length > 0 && !error 
            ? "Document marked for redownload" 
            : "Document not marked for removal. Check Sentry", 
            { type: data && data.length > 0 && !error ? "info" : "warning" }
        );
        refresh();
        unselect(selectedIds)
        setOpen(false);
    };

    return (
        <>
            <RedownloadButton id="RedownloadButton" label="Mark for Redownload" onClick={() => setOpen(true)} />
            <Confirm
                isOpen={open}
                title="Mark selected documents for redownload"
                content="Are you sure you want to submit these documents for redownload"
                onConfirm={handleConfirm}
                onClose={() => setOpen(false)}
            />
        </>
    );
};


export default DocumentRedownloadButton;



import React, { useState } from "react";
import { useDataProvider, useNotify, usePermissions, useRecordContext } from "react-admin";
import { CircularProgress, Paper, Table, TableBody, TableRow, TableCell, Checkbox, TextField, Button } from '@mui/material'
import { renderIfPermissions } from "../api/permissionManager";

const PermissionedCheckbox = ({ label, checked, onChange }) => {
  const { permissions } = usePermissions();

  return renderIfPermissions(permissions, 'clients', "write", (
    <>
    <TableCell component="th" scope="row">{label}</TableCell>
    <TableCell align="left">
      <Checkbox
        checked={checked}
        onChange={onChange}
      />
    </TableCell>
    </>
  ));
};

const ThresholdToggle = ({ userJson, updateSpeedThreshold }) => {
  const { permissions } = usePermissions();

  if (userJson) {
    if (userJson.disable_speed_threshold === true) {
      return renderIfPermissions(permissions, "clients", "write",
        <Button
          id="toggle-button"
          variant="contained"
          color="primary"
          onClick={() => updateSpeedThreshold(false)}
        >
          Enable Speed Threshold
        </Button>
      );
    } else {
      return renderIfPermissions(permissions, "clients", "write",
        <Button
          id="toggle-button"
          variant="contained"
          color="primary"
          onClick={() => updateSpeedThreshold(true)}
        >
          Disable Speed Threshold
        </Button>
      );
    }
  }

  return null;
};

export const UserSearchForm = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [username, setUsername] = useState("");
  const [userJson, setUserJson] = useState(null);
  const [loading, setLoading] = useState(false);
  const clientKey = record.key;
  const dataProvider = useDataProvider();

  const submitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    await dataProvider.getOne("platform_users", {clientKey: clientKey, username: username })
    .then(({ data }) => {
      setUserJson(data);
    }).catch(error => {
      notify("Error fetching user. Check Sentry.", {type: 'error'});
      setUserJson(null);
    });
    setLoading(false);
  };

  const updateSpeedThreshold = async (value) => {
    await dataProvider.update("platform_users", {clientKey, username, body: { disable_speed_threshold: value }})
    .then(({ data }) => {
      setUserJson(data);
    }).catch(error => {
      notify("Error updating speed threshold. Check Sentry.", {type: 'error'})
    })
    setLoading(false);
  };

  const userShow = () => {
    if (loading) return <div style={{ marginLeft: "40%" }}><CircularProgress /></div>;
    if (!userJson) return <div style={{ marginLeft: "35%" }}><h2>User Not Found</h2></div>;

    return (
      <div style={{ marginLeft: "25%", width: "40%" }}>
        <Paper>
          <Table>
          <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Username
                  </TableCell>
                  <TableCell align="left">{userJson.username}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Full Name
                  </TableCell>
                  <TableCell align="left">{userJson.full_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email
                  </TableCell>
                  <TableCell align="left">{userJson.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Active
                  </TableCell>
                  <TableCell align="left"><Checkbox checked={userJson.active} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    App Version
                  </TableCell>
                  <TableCell align="left">{userJson.last_drive_axle_version}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Device Name
                  </TableCell>
                  <TableCell align="left">{userJson.last_device_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Device Identifier
                  </TableCell>
                  <TableCell align="left">{userJson.last_device_identifier}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Device Type
                  </TableCell>
                  <TableCell align="left">{userJson.last_device_type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Created At
                  </TableCell>
                  <TableCell align="left">{userJson.created_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Opened App
                  </TableCell>
                  <TableCell align="left">{userJson.current_sign_in_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Updated At
                  </TableCell>
                  <TableCell align="left">{userJson.updated_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Sign In
                  </TableCell>
                  <TableCell align="left">{userJson.last_sign_in_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Refresh
                  </TableCell>
                  <TableCell align="left">{userJson.last_refreshed_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sign In Count
                  </TableCell>
                  <TableCell align="left">{userJson.sign_in_count}</TableCell>
                </TableRow>
                <TableRow>
                  <PermissionedCheckbox label={"Disallow Metered Map Downloads"} checked={userJson.disallow_metered_map_downloads} onChange={() => { }} />
                </TableRow>
                <TableRow>
                  <PermissionedCheckbox label={"Soft Here Routing Restrictions"} checked={userJson.soft_here_routing_restrictions} onChange={() => { }} />
                </TableRow>
                <TableRow>
                  <PermissionedCheckbox label={"Force Online Hours Of Service"} checked={userJson.force_online_hours_of_service} onChange={() => { }} />
                </TableRow>
                <TableRow>
                  <PermissionedCheckbox label={"Disable Speed Threshold"} checked={userJson.disable_speed_threshold} onChange={() => { }} />
                </TableRow>
              </TableBody>
          </Table>
        </Paper>
        <ThresholdToggle
            userJson={userJson}
            updateSpeedThreshold={updateSpeedThreshold}
          />
      </div>
    );
  };

  return (
    <div>
      <div style={{ marginLeft: "35%" }}>
        <h2>Search For User</h2>
        <form onSubmit={submitHandler}>
          <TextField
            variant="outlined"
            label="Username"
            size="small"
            onChange={(e) => setUsername(e.target.value)}
            style={{ margin: "8px" }}
          />
          <Button type="submit" variant="contained" color="primary">
            Search
          </Button>
        </form>
      </div>
      {userShow()}
    </div>
  );
};

import React from "react";
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { DocGroup, DocItem } from "../components/DocFields";

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
}));

export default function CustomAccountContractCreateDoc() {
  return (
    <DocGroup title="(More Info Custom Contract)">
      <DocItem title="Billing Account External ID">
        This is the UUID used to identify the specific Billing Account
      </DocItem>
      <DocItem title="Contract Structure Code">
        <Typography>
          <p>This represents the arrangement of different products for a contract.</p>
          <ul>
            <li>For resellers, these are standard and consist of things like a per-user fee, per-month fee, and an app maintenance fee.</li>
            <li>If this is a reseller account, this field should be <i>exactly</i> like "RESELLER-2020".</li>
            <li>The code entered here must match a real contract structure code in the database.</li>
            <li>This <b>CANNOT</b> be altered later</li>
          </ul>
        </Typography>
      </DocItem>
      <DocItem title="Price Sheet Code">
        <Typography>
          <p>This represents the list of prices per product that correspond to the products on the Contract Structure.</p>
          <ul>
            <li>These are standard for resellers, so the field must be <i>exactly</i> like "RESELLER-2020".</li>
            <li>The code entered here must match a real price sheet code in the database.</li>
            <li>This <b>CANNOT</b> be altered later</li>
          </ul>
        </Typography>
      </DocItem>
      <DocItem title="Valid From">
        <Typography>
          <p>This is the first date on which the contract is valid.</p>
          <ul>
            <li>If this is a new reseller account, enter the first day of the month when the contract goes live, which is usually the current month as well.
            </li>
            <li>This <b>CANNOT</b> be altered later</li>
          </ul>
        </Typography>
      </DocItem>
      <DocItem title="Contract Anniversary">
        <Typography>
          <p>This is the first day of the first month that the customer has service with us; it is is used to determine when to charge certain annual fees.</p>
          <ul>
            <li>Often the same as 'Valid From' for new accounts.</li>
            <li>This <b>CANNOT</b> be altered later</li>
          </ul>
        </Typography>
      </DocItem>
    </DocGroup>
  )
}
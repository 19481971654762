import React, { useState } from "react";
import { Button, Typography, Divider } from "@mui/material";
import { DialogContent, DialogActions, Dialog } from '@mui/material';
import { useDataProvider, useNotify } from 'react-admin';

export const ResetPasswordButton = ({record, ...props}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);

  const passwordResetRequest = async (email) => {  
    const {data: response, error} = await dataProvider.update('scanning_users', {id: email});
    if(!response || error) notify(`Password reset request failed. Check Sentry.`, { type: "warning"});
    else notify(`Password reset request sent to: ${email}`, { type: "info"});
  };
    
  const buttonStyles = {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "15px",
    paddingBottom: "20px"
  };
  
  const labelStyles = {
    color: "rgba(0, 0, 0, 0.54)",
    marginBottom: "8px"
  };

  const handleResetRequest = (rec) => {
    passwordResetRequest(rec.email)
  };

  const ConfirmationDialog = (props) => {
    const handleCancel = () => {
      setOpen(false)
      notify((`Password reset request canceled`), {type: 'info'})
    };

    const handleOk = () => {
      setOpen(false)
      handleResetRequest(record)
    };

    return (
      <Dialog maxWidth="sm" open={open}>
        <DialogContent>
          <Typography variant="subtitle1">{`Send a password reset email to ${record.email}?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus aria-label="cancel-reset" onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button aria-label="confirm-reset" onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
    <Divider style={{marginTop: "8px"}}/>
    <div style={buttonStyles} > 
        <Typography variant="overline" style={labelStyles}>Send a password reset email:</Typography>
        <div>
          <Button color="primary" size="small" variant="outlined" onClick={() => setOpen(true)} aria-label={"reset-pw-button"}>Send</Button>
        </div>
    </div>
    <ConfirmationDialog {...props}/>
    </>
  )
}